import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";

import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";
import CourseCatalog from "../FormOvering/CourseCatalog";
import CardProfilePCF from "../Current/CardProfilePCF";
import { openToast } from "../../../../../Library/ToastPopUp";
import CardCourseHist from "./CardCourseHist";
import LoadingBtn from "../../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../../Library/AlertNotif";
import MYPCF from "./MYPCF";
import PreviewPCFR from "./PreviewPCFR";

export function RevisionPCF() {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const atp_group_bachelor_reg = "asc.atp.group.type.bachelor.regular";
  const [typeAtp, setTypeAtp] = useState("");
  const [isbachelorReg, setIsBachelorReg] = useState(false);

  const studentid = AuthDecode.currentData.entityid;
  const [mypcf, setMypcf] = useState([]);
  const [totalsks, setTotalSKS] = useState(0);
  const [mobile, setMobile] = useState({
    phone: "",
    rephone: "",
    match: "",
    message: "",
  });
  const [parent_email, setParentEmail] = useState({ mail: "", message: "", match: false });

  const [pcf, setPCF] = useState({ loading: false, data: [], message: "" });
  const GET_REREG_STUDENT_REVISION = () => {
    setPCF({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: studentid.toString(),
      },
      target_data: "GET_REREG_STUDENT_REVISION",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setPCF({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        setPCF({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        var results = response.response.data;
        if (results) {
          //OpenTermCondition();
          var arrpcfrev = results.filter((prev) => prev.in_semester === 1);
          var myPcfrev = GetMyPCFRev(arrpcfrev);
          setMypcf(myPcfrev);
          setPCF({ loading: false, data: results, message: "" });
        } else {
          setPCF({ loading: false, data: [], message: "No record found" });
        }
      }
    });
  };

  const [stdStatus, setStdStatus] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [maxSKS, setMaxSKS] = useState(24);
  const GET_STUDENT_STATUS = () => {
    setStdStatus({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: studentid.toString(),
      },
      target_data: "GET_STUDENT_STATUS",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdStatus({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        setStdStatus({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setStdStatus({ loading: false, data: results, message: "" });
          const atp_type_bachelor = (results?.atp_group_type) || "";
          setTypeAtp(atp_type_bachelor);
          if (atp_type_bachelor === atp_group_bachelor_reg) {
            setIsBachelorReg(true);
          } else {
            setIsBachelorReg(false);
          }
          GET_ACTIVE_ACAD(results.atp_group_type);
        } else {
          setStdStatus({
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  const [selectedAcad, setSelectedAcad] = useState(0);
  const [stdAcad, setStdAcad] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const GET_ACTIVE_ACAD = (acadType) => {
    setStdAcad({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      target_data: "GET_ACTIVE_ACAD",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStdAcad({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        setStdAcad({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        var results = response.response.data;
        if (results) {
          var selectIndex = results.findIndex(
            (v) => v.atp_group_type.id === acadType
          );
          setStdAcad({
            loading: false,
            data: results[selectIndex],
            message: "",
          });
          setSelectedAcad(results[selectIndex].reff_atp_id_term);
        } else {
          setStdAcad({ loading: false, data: [], message: "No record found" });
        }
      }
    });
  };

  const [counselor, setCounselor] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const GET_COUNSELOR = () => {
    setCounselor({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        studentid: studentid.toString(),
      },
      target_data: "GET_COUNSELOR",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setCounselor({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        setCounselor({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setCounselor({ loading: false, data: results, message: "" });
        } else {
          setCounselor({
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  useEffect(() => {
    GET_STUDENT_STATUS();
  }, []);

  const [hasSubmitPCF, setHasSubmitPCF] = useState(false);

  useEffect(() => {
    const stdActivity = stdStatus.data.semester_activity;

    if (stdStatus.data.corporate && stdStatus.data.corporate.id !== 3) {
      setHasSubmitPCF(false);
    } else {
      if (stdActivity) {
        const findActivity = stdActivity.find(
          (item) =>
            item.student_activity.id === "asc.student.activity.pcfr" &&
            item.atp_id_term === selectedAcad
        );
        if (findActivity) {
          setHasSubmitPCF(true);
        }
      }
    }
  }, [stdStatus.data, selectedAcad]);

  useEffect(() => {
    GET_REREG_STUDENT_REVISION();
    GET_COUNSELOR();
  }, []);

  const handlerActionCourse = (value) => {
    if (value.action_type === "add") {
      //check existing course
      var isSame = mypcf.filter(
        (prev) =>
          prev.section_id === value.section_id ||
          prev.detail.course_code === value.detail.course_code
      );
      if (Object.values(isSame).length > 0) {
        const hasDifferentActionType = mypcf.some(
          (item) =>
            (item.course_id === value.course_id ||
              item.section_id === value.section_id) &&
            item.action_type === "remove"
        );
        console.log(hasDifferentActionType);
        if (hasDifferentActionType) {
          setMypcf([...mypcf, value]);
        } else {
          openToast({
            header: "Information",
            message:
              "This course has already been added to the 'PCF' (please check the Trash)",
          });
        }
      } else {
        setMypcf([...mypcf, value]);
      }
    }
  };

  const GetMyPCFRev = (arrData) => {
    var computedData = arrData.map((v) => {
      var obj = {};
      var detail = {};
      obj.course_id = v.course_id;
      obj.section_id = v.section_id;
      obj.origin = true;
      //obj.action_type = "add";
      detail.coursetitle = v.coursetitle;
      detail.course_id = v.course_id;
      let typeArr = v.course_type.split(".");
      var c_type = typeArr[typeArr.length - 1];
      detail.course_type = c_type;
      detail.course_code = v.code;
      detail.course_credit = v.credit;
      detail.section_id = v.section_id ? v.section_id : "-";
      obj.detail = detail;
      return obj;
    });
    return computedData;
  };

  const handlerRepeat = (value) => {
    var message = "";
    if (value) {
      if (value === mobile.phone) {
        message = "match";
      } else {
        message = "not match";
      }
    }
    setMobile({ ...mobile, rephone: value, match: message });
  };

  let objparamPCF = {
    cellular: mobile.phone,
    studentid: studentid.toString(),
    email: parent_email.mail,
  };

  const [openPrev, setOpenPrev] = useState(false);
  const [previewPCF, setPreviewPCF] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const GET_PCF_AMOUNT = (param) => {
    setPreviewPCF({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };

    var body = {
      parameters: param,
      target_data: "GET_PCF_AMOUNT",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if ("message" in response.response) {
        setPreviewPCF({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
        openToast({
          message: "Error " + response.response.message.user,
          header: "Error",
        });
      } else {
        var result = response.response.result;
        if (result === "success") {
          setOpenPrev(true);
          setPreviewPCF({
            loading: false,
            data: response.response.data,
            message: "",
          });
          window.scrollTo(0, 0);
        } else {
          setPreviewPCF({
            loading: false,
            data: [],
            message: response.response.msg,
          });
          openToast({
            message: response.response.msg,
            header: "Error",
          });
        }
      }
    });
  };

  const isValidEmail = (val) => {
    let message = "", match = false;
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      match = false
      message = "Invalid Email Address";
    } else {
      match = true;
      message = "";
    }

    setParentEmail({ mail: val, message: message, match: match });
  };

  useEffect(() => {
    if (stdStatus.data && stdStatus.data.corporate && stdStatus.data.corporate.id !== 3) {
      setMaxSKS(totalsks);
    } else {
      setMaxSKS(24);
    }
  }, [totalsks])

  const SubmitPreviewAmount = (e) => {
    e.preventDefault();

    if (totalsks > maxSKS) {
      openToast({ header: "Information", message: "Maximum total SKS is 24" });
    } else {
      if (Object.values(mypcf).length > 0 && objparamPCF.cellular) {
        var arrPrev = [...mypcf];

        var courses = arrPrev.map((c) => {
          var obj = {};
          obj.action_type = c.action_type ? c.action_type : "add";
          obj.course_id = c.course_id;
          obj.section_id = c.section_id;
          return obj;
        });

        var paramPrev = {};
        paramPrev.courses = courses;
        paramPrev.studentid = studentid.toString();
        paramPrev.term_id = stdAcad.data.reff_atp_id_term;

        let is_next_prev = false;
        if (isbachelorReg) {
          if (parent_email.match) {
            is_next_prev = true;
            paramPrev.email = parent_email.mail;
            objparamPCF.email = parent_email.mail;
          }
          else is_next_prev = false;
        } else {
          is_next_prev = true;
        }

        if (is_next_prev) {
          if (
            stdStatus.data.term_intake.id === selectedAcad ||
            stdStatus.data.enrollment_type_id ===
            "mkt.enrollment.type.cooperation" ||
            stdStatus.data.enrollment_type_id === "mkt.enrollment.type.exchange"
          ) {
            setOpenPrev(true);
          } else {
            GET_PCF_AMOUNT(paramPrev);
          }
        } else {
          openToast({
            header: "Information",
            message: "Please fill up the form with correctly",
          });
        }
      } else {
        openToast({
          header: "Information",
          message: "Please fill up the form with correctly",
        });
      }

      //openModal({ header: "Preview PCF Revision", message: <PreviewPCFRevision data={resPrev} handlerSubmitPCF={handlerSubmitPCF} />, size: "xl" })
    }
  };

  if (hasSubmitPCF) {
    return (
      <div className="p-5 bg-white border border-top-0">
        <div className="border rounded p-5 text-center bg-light">
          <span className="svg-icon svg-icon-primary svg-icon-2x">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z"
                  fill="#000000"
                  opacity="0.3"
                />
                <polygon
                  fill="#000000"
                  points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912"
                />
              </g>
            </svg>
          </span>
          <p className="text-primary mb-0">
            You have already submitted a PCF Revision
          </p>
        </div>

        <div className="mt-8 text-center">
          <Link
            to={"/registration/plan-course-form"}
            type="button"
            className="btn font-weight-bolder text-uppercase font-size-lg btn-primary py-2 px-6"
          >
            <span>Back </span>
          </Link>
        </div>
      </div>
    );
  } else if (Object.values(previewPCF.data).length > 0 || openPrev === true) {
    return (
      <PreviewPCFR
        data={previewPCF.data}
        totalsks={totalsks}
        param={objparamPCF}
        setSubmitPCFOri={setPreviewPCF}
        mypcf={mypcf}
        setOpenPrev={setOpenPrev}
      />
    );
  } else {
    return (
      <div id="revision-pcf">
        <div
          className="alert alert-custom alert-white alert-shadow fade show gutter-b"
          role="alert"
        >
          <div className="alert-icon">
            <span className="svg-icon svg-icon-primary svg-icon-xl">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
                    fill="#000000"
                    opacity="0.3"
                  ></path>
                  <path
                    d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
          <div className="alert-text">
            <h3>
              PCF Revision for{" "}
              {Object.values(stdAcad.data).length > 0
                ? (stdAcad.data.reff_atp_term && Object.values(stdAcad.data.reff_atp_term).length > 0)
                  ? stdAcad.data.reff_atp_term.name
                  : "No reference ATP term"
                : ""}
            </h3>
          </div>
        </div>

        {pcf.loading ? (
          <LoadingBtn />
        ) : pcf.message ? (
          <>
            <AlertNotif
              title={"Information"}
              message={pcf.message}
              color={"info"}
            />
            <Link
              to={{ pathname: "/registration/plan-course-form" }}
              className="btn btn-warning  font-weight-bolder text-uppercase px-9 py-4"
            >
              <i className="fa fa-arrow-left"></i>
              Back to history PCF
            </Link>
          </>
        ) : (
          <>
            <div className="row">
              <div className={"col-12"}>
                <CardProfilePCF studentid={AuthDecode.currentData.entityid} />
              </div>

              <div className="col-4">
                {pcf.loading ? (
                  <LoadingBtn />
                ) : pcf.message ? (
                  <AlertNotif
                    title={"Information"}
                    message={pcf.message}
                    color={"danger"}
                  />
                ) : Object.values(pcf.data).length > 0 ? (
                  <CardCourseHist data={pcf.data} />
                ) : (
                  ""
                )}
              </div>
              <div className="col-4">
                {selectedAcad ? (
                  <CourseCatalog
                    handlerActionCourse={handlerActionCourse}
                    student={stdStatus.data}
                    selectedAcad={selectedAcad}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-4">
                {pcf.loading ? (
                  <LoadingBtn />
                ) : pcf.message ? (
                  <AlertNotif
                    title={"Information"}
                    message={pcf.message}
                    color={"danger"}
                  />
                ) : Object.values(pcf).length > 0 ? (
                  <MYPCF
                    maxSKS={maxSKS}
                    mypcf={mypcf}
                    setMypcf={setMypcf}
                    acad={stdAcad.data}
                    totalsks={totalsks}
                    setTotalSKS={setTotalSKS}
                    handlerActionCourse={handlerActionCourse}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="card card-custom gutter-b">
              <div className="card-body">
                <form
                  method="post"
                  autoComplete="off"
                  onSubmit={(e) => SubmitPreviewAmount(e)}
                >
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="font-weight-bolder">
                          Student WhatsApp Number <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="text"
                          required
                          name="phone"
                          minLength={10}
                          className={
                            "form-control " +
                            (Object.values(mobile.phone).length > 10
                              ? "is-valid"
                              : "is-invalid")
                          }
                          placeholder="081xxx"
                          onChange={(e) =>
                            setMobile({
                              ...mobile,
                              phone: e.target.value.replace(/\D/g, ""),
                            })
                          }
                          value={mobile.phone}
                        />
                        <span className="text-danger">
                          {!mobile.phone && "Fill up this field"}
                          {mobile.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label className="font-weight-bolder">
                          Confirm Student WhatsApp Number {" "}
                          <sup className="text-danger">*</sup>
                        </label>
                        <input
                          type="text"
                          required
                          onPaste={handlePaste}
                          name="repeat-phone"
                          minLength={10}
                          className={
                            "form-control " +
                            (mobile.match === "match"
                              ? "is-valid"
                              : "is-invalid")
                          }
                          placeholder="081xxx"
                          onChange={(e) =>
                            handlerRepeat(e.target.value.replace(/\D/g, ""))
                          }
                          value={mobile.rephone}
                        />
                        <span className="text-danger">{mobile.message}</span>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      {isbachelorReg && (
                        <div className="form-group">
                          <label className="font-weight-bolder">
                            Parent Email Address <sup className="text-danger">*</sup>
                          </label>
                          <input
                            type="text"
                            required
                            name="parent_email"
                            className={
                              "form-control " +
                              (!parent_email.match
                                ? "is-invalid"
                                : "is-valid")
                            }
                            onChange={(e) =>
                              isValidEmail(e.target.value)
                            }
                            value={parent_email.mail}
                          />
                          <span className="text-danger">
                            {!parent_email.mail && "Fill up this field"}
                            {parent_email.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3">
                      {Object.values(counselor.data).length > 0 ? (
                        <div className="form-group">
                          <label className="font-weight-bolder">
                            Counselor
                          </label>
                          {counselor.data.map((v, index) => (
                            <div className="list-counselor py-2" key={index}>
                              <span className="font-weight-bolder">
                                {v.emp ? v.emp.fullname : "-"}
                              </span>
                              {index <
                                Object.values(counselor.data).length - 1 ? (
                                <span className="mr-1">, </span>
                              ) : (
                                ""
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="alert alert-danger">
                          <div className="alert-title text-left">
                            <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                            You do not have a counselor.
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 btn-submit"
                      type="submit"
                      disabled={
                        previewPCF.loading ? true :
                          mobile.rephone ? (mobile.phone === mobile.rephone ? (
                            false
                          ) : true) : true
                      }
                    >
                      {previewPCF.loading ? "Processing" : "Next and Preview"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const handlePaste = (e) => {
  e.preventDefault();
  return false;
};
