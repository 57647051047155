import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import LoadingBtn from '../../../Widgets/LoadingBtn';
import { AlertNotif } from '../../../../Library/AlertNotif';
import { openToast } from '../../../../Library/ToastPopUp';
import AxiosLibWithHeader2 from '../../../../Library/AxiosLibWithHeader2';
import { openModal } from '../../../../Library/ModalPopUp';

export default function Form(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

    const stdStatus = props.stdStatus;
    const acad = props.stdAcad;

    const atp_group_bachelor_reg = "asc.atp.group.type.bachelor.regular";
    const [isbachelorReg, setIsBachelorReg] = useState(false);

    useEffect(() => {
        if (stdStatus.data && stdStatus.data.atp_group_type === atp_group_bachelor_reg) {
            setIsBachelorReg(true);
        }
    }, [props.stdStatus.data])

    const [activity, setActivity] = useState({ loading: false, data: [], message: "" });
    const GET_STUDENT_ACTIVITY = () => {
        setActivity({ loading: true, data: [], message: "" });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: {
                studentid: AuthDecode.currentData.entityid,
            },
            target_data: "GET_STUDENT_ACTIVITY",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setActivity({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setActivity({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                results.sort(function (a, b) {
                    return b.id - a.id;
                })
                if (Object.keys(results).length > 0) {
                    setActivity({ loading: false, data: results, message: "" });
                } else {
                    setActivity({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    }

    useEffect(() => {
        GET_STUDENT_ACTIVITY();
    }, [])

    const objParam = {
        cellular: "",
        note: "",
        email: "",
        studentid: AuthDecode.currentData.entityid.toString()
    }
    const [postLeave, setPostLeave] = useState(objParam);
    const [submitLeave, setSubmitLeave] = useState({ loading: false, data: [], message: "" });
    const handlerSubmit = (e) => {
        e.preventDefault();
        if (AuthDecode.remotedBy) {
            openToast({ header: "Error", message: "You cannot access this request" });
        } else {
            if (postLeave.cellular && (postLeave.note && Object.values(postLeave.note).length >= 15)) {
                let isAvailable = false;
                if (isbachelorReg) {
                    if (postLeave.email) {
                        isAvailable = true;
                    }
                } else {
                    isAvailable = true;
                }

                if (isAvailable) {
                    var my_atp_term_id = stdStatus.data.atp_term ? stdStatus.data.atp_term.id : 0;
                    var findAct = activity.data.filter(item => item.atp_id_term === my_atp_term_id);
                    if (Object.values(findAct).length > 0) {
                        openModal({ header: "Confirmation", message: <ConfirmDialogPCF /> });
                    } else {
                        openModal({ header: "Confirmation", message: <ConfirmDialog /> });
                    }
                }else{
                    openToast({ header: "Information", message: "Please fill out this filed with correctly" });
                }
            } else {
                openToast({ header: "Information", message: "Please fill out this filed with correctly" });
            }
        }
    }

    const ConfirmDialog = () => {
        return (
            <div className="confirm text-center">
                <p className='font-weight-bolder h3 mb-10'>Are you sure want to leave semester in <br /> Periode {acad.reff_atp_term.name} ?</p>
                <button className="btn btn-primary btn-lg font-weight-bolder" type='button' onClick={() => SUBMIT_LEAVE_STUDENT_ACTIVECALENDAR()}>
                    SUBMIT
                </button>
            </div>
        )
    }

    const ConfirmDialogPCF = () => {
        return (
            <div className="confirm text-center">
                <p className='font-weight-bolder h3 mb-10'>This leave application is auto-approved and will result in the cancellation of your current submitted PCF. Do you want to continue?</p>
                <button className="btn btn-light mr-3 btn-lg font-weight-bolder" type='button' onClick={() => openModal({ open: false })}>No</button>
                <button className="btn btn-primary btn-lg font-weight-bolder" type='button' onClick={() => SUBMIT_LEAVE_STUDENT_ACTIVECALENDAR()}>
                    Yes
                </button>
            </div>
        )
    }

    const SUBMIT_LEAVE_STUDENT_ACTIVECALENDAR = () => {

        setSubmitLeave({ loading: true, data: [], message: "" });
        openModal({ open: false });
        var keys = {
            AppId: AuthDecode.AppID,
            AppEnv: AuthDecode.AppEnv,
            Secretkey: AuthDecode.secretkey,
        };
        var body = {
            parameters: postLeave,
            target_data: "SUBMIT_LEAVE_STUDENT_ACTIVECALENDAR",
        };
        const postData = {
            keys: keys,
            body: body,
        };

        AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
            if (response.error) {
                setSubmitLeave({ loading: false, data: [], message: response.error });
            } else if ("message" in response.response) {
                setSubmitLeave({ loading: false, data: [], message: response.response.message.user });
            } else {
                var results = response.response.data;
                if (results) {
                    setSubmitLeave({ loading: false, data: results, message: "" });
                    openToast({ header: "Information", message: "This request will send notification to your faculty." });
                    props.GET_STUDENT_ACTIVITY();
                } else {
                    setSubmitLeave({ loading: false, data: [], message: "No record found" });
                }
            }
        });
    }

    const isValidEmail = (val) => {
        let message = "",
            match = false;
        let regEmail =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
            match = false;
            message = "Invalid Email Address";
        } else {
            match = true;
            message = "";
        }

        if (match) {
            setPostLeave({ ...postLeave, email: val });
        }
    };

    return (
        <div className="card card-custom gutter-b">
            <div className="card-header align-items-center border-0">
                <h3 className="card-title align-items-start flex-column">
                    <span className="font-weight-bolder text-dark">Form Leave Semester</span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">Please fill up the form with correctly</span>
                </h3>
            </div>
            <div className="card-body">
                {stdStatus.loading ? <LoadingBtn /> : (
                    stdStatus.message ? <AlertNotif message={stdStatus.message} color={"danger"} /> : (
                        Object.values(stdStatus.data).length > 0 ? (
                            <form method="post" autoComplete='off' onSubmit={(e) => handlerSubmit(e)} >
                                <div className="row mb-7">
                                    <label className='col-lg-4 font-weight-bold text-muted'>Fullname</label>
                                    <div className="col-lg-8">
                                        <span className="font-weight-bold font-size-lg text-dark">{((AuthDecode) ? AuthDecode.currentData.given_name + ' ' + AuthDecode.currentData.family_name : '-')}</span>
                                    </div>
                                </div>
                                <div className="row mb-7">
                                    <label className='col-lg-4 font-weight-bold text-muted'>Student ID</label>
                                    <div className="col-lg-8">
                                        <span className="font-weight-bold font-size-lg text-dark">{stdStatus.data.studentid}</span>
                                    </div>
                                </div>

                                <div className="row mb-7">
                                    <label className='col-lg-4 font-weight-bold text-muted'>Program</label>
                                    <div className="col-lg-8">
                                        <span className="font-weight-bold font-size-lg text-dark">{(stdStatus.data.program) ? stdStatus.data.program.name : "-"}</span>
                                    </div>
                                </div>

                                <div className="row mb-7">
                                    <label className='col-lg-4 font-weight-bold text-muted'>Department</label>
                                    <div className="col-lg-8">
                                        <span className="font-weight-bold font-size-lg text-dark">{(stdStatus.data.clu) ? stdStatus.data.clu.name : "-"}</span>
                                    </div>
                                </div>
                                {stdStatus.data.clu_spec ? (
                                    <div className="row mb-7">
                                        <label className='col-lg-4 font-weight-bold text-muted'>Specialization</label>
                                        <div className="col-lg-8">
                                            <span className="font-weight-bold font-size-lg text-dark">{(stdStatus.data.clu_spec) ? stdStatus.data.clu_spec.name : "-"}</span>
                                        </div>
                                    </div>
                                ) : ""}

                                <div className="row mb-7 d-none">
                                    <label className='col-lg-4 font-weight-bold text-muted'>Semester</label>
                                    <div className="col-lg-8">
                                        <span className="font-weight-bold font-size-lg text-dark">{stdStatus.data.semester}</span>
                                    </div>
                                </div>

                                {/* {Object.values(counselor.data).length > 0 ? (
                                    counselor.data.map((v, index) => (
                                        <div className="row mb-7" key={index}>
                                            <label className='col-lg-4 font-weight-bold text-muted'>Counselor</label>
                                            <div className="col-lg-8">
                                                <span className="font-weight-bold font-size-lg text-dark">{(v.emp) ? v.emp.fullname : "-"}</span>
                                            </div>
                                        </div>
                                    ))
                                ) : ""} */}

                                <div className="row mb-7">
                                    <label className='col-lg-4 font-weight-bold text-muted'>Mobile Phone Number</label>
                                    <div className="col-lg-8">
                                        <input type="text" name="phone" required className='form-control' placeholder='081xxx' onChange={(e) => setPostLeave({ ...postLeave, cellular: e.target.value.replace(/\D/g, "") })} defaultValue={postLeave.cellular} />
                                    </div>
                                </div>

                                {isbachelorReg && (
                                    <div className="row mb-7">
                                        <label className='col-lg-4 font-weight-bold text-muted'>Parent Email Address</label>
                                        <div className="col-lg-8">
                                            <input type="text" name="email" required className='form-control' onChange={(e) => isValidEmail(e.target.value)} defaultValue={postLeave.email} />
                                        </div>
                                    </div>
                                )}

                                <div className="row mb-7">
                                    <label className='col-lg-4 font-weight-bold text-muted'>Reason</label>
                                    <div className="col-lg-8">
                                        <input type="text" name="note" required className={'form-control ' + ((Object.values(postLeave.note).length >= 15) ? "is-valid" : "is-invalid")} maxLength={255} minLength={15} onChange={(e) => setPostLeave({ ...postLeave, note: e.target.value })} defaultValue={postLeave.note} />
                                        <span className="text-muted">Type minimum 15 character</span>
                                    </div>
                                </div>

                                {(AuthDecode.remotedBy) ? (
                                    <div className="alert alert-danger">
                                        <div className="alert-title text-left">
                                            <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                                            You cannot submit this Session.<br />This session belongs to <b>Mr/Mrs. {AuthDecode.currentData.given_name + " " + AuthDecode.currentData.family_name}</b>
                                        </div>
                                    </div>
                                ) : ''}

                                <div className="text-right mb-10">
                                    <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 btn-submit" type='submit' disabled={submitLeave.loading}>
                                        {submitLeave.loading ? "Processing..." : "Submit"}
                                    </button>
                                </div>

                                {submitLeave.loading ? (
                                    <AlertNotif message={"We are still processing you data please wait do not refresh the page"} color={"info"} />
                                ) : ""}

                                {submitLeave.message ? (
                                    <AlertNotif message={submitLeave.message} color={"danger"} />
                                ) : ""}

                            </form>
                        ) : (
                            <div className="alert alert-primary">
                                <div className="alert-title text-left">
                                    <i className="icon-1x text-white flaticon-warning-sign mr-2"></i>
                                    No record found
                                </div>
                            </div>
                        )
                    )
                )}
            </div>
        </div>
    )
}
