import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import parse from "html-react-parser";
import Ava1 from "../../../../../media/avatars/17-dark.png";
import { openModal } from "../../../../Library/ModalPopUp";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import { CleansingJSON } from "../../../../Library/GlobalHelper";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../Library/AlertNotif";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export default function Notifications({ stdstatus, exept, ispopup }) {
  const [notifInfo, setNotifInfo] = useState({loading: false, data: [], message: "" });

  const GET_SYS_PARAM = () => {
    const type_degree = stdstatus.program.id === 1 ? "bachelor":"master";
    const param = { tipe: "pcf-information", name: type_degree };
    setNotifInfo({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };

    const postData = {
      keys: keys,
      body: param,
    };

    AxiosLibWithHeader2(postData, "mc/api/sys-param").then((response) => {
      if (response.data) {
        const data = response.data;
        const conv = CleansingJSON(data);
        setNotifInfo({ message: "", loading: false, data: conv });
      } else if (response.error) {
        setNotifInfo({ data: [], loading: false, message: response.error });
      } else {
        setNotifInfo({ data: [], loading: false, message: "" });
      }
    });
  };

  useEffect(()=>{
    GET_SYS_PARAM();
  },[])

  

//   let notifArr = [];
//   if (stdstatus.program.id === 1) {
//     notifArr = [
//       {
//         id: 1,
//         title: "Rereg PCF",
//         message: parse(
//           'PCF registration is <span className="text-danger">open</span>'
//         ),
//         link: "/registration/plan-course-form",
//         label: "Start Registration",
//       },
//       {
//         id: 2,
//         title: "Deadline Re-Registration and Plan Course Form (PCF) Submission",
//         message: parse(
//           'Deadline Re-Registration and Plan Course Form (PCF) Submission <br/><span className="text-danger">Odd Semester 2024/2025</span>'
//         ),
//         link: "https://drive.google.com/file/d/1Z0lM2MDcSImXtra8CDcOoT1VaJRRymZh/view?usp=drive_link",
//         label: "Download here",
//       },
//       {
//         id: 3,
//         title:
//           "Procedures of Re-Registration and Plan Course Form (PCF) Submission",
//         message: parse(
//           'Procedures of Re-Registration and Plan Course Form (PCF) Submission <br/><span className="text-danger">Odd Semester 2024/2025</span>'
//         ),
//         link: "https://drive.google.com/file/d/1Z0kgWxOixtb5iGCzo7GZtV3T0Zgyo7yg/view?usp=drive_link",
//         label: "Download here",
//       },
//       {
//         id: 4,
//         title: "Tuition fee for Odd Semester 2024/2025",
//         message: parse(
//           'Tuition fee for <br/><span className="text-danger">Odd Semester 2024/2025</span>'
//         ),
//         link: "https://drive.google.com/file/d/1xmTbEUYs7O8egDAeU2boZ5eD_PY59w6U/view?usp=drive_link",
//         label: "Download here",
//       },
//     ];
//   } else {
//     notifArr = [
//       {
//         id: 1,
//         title: "Rereg PCF",
//         message: parse(
//           'PCF registration is <span className="text-danger">open</span>'
//         ),
//         link: "/registration/plan-course-form",
//         label: "Start Registration",
//       },
//       {
//         id: 2,
//         title: "Tuition fee for Even Semester 2023/2024",
//         message: parse(
//           'Deadline of tuition fee payment for Plan Course Form (PCF) Submission <br/><span className="text-danger">Odd Semester 2023/2024</span>'
//         ),
//         link: "#",
//         label: "Download here",
//       },
//       {
//         id: 4,
//         title:
//           "Procedures of Re-Registration and Plan Course Form (PCF) Submission",
//         message: parse(
//           'Procedures of Re-Registration and Plan Course Form (PCF) Submission <br/><span className="text-danger">Odd Semester 2023/2024</span>'
//         ),
//         link: "#",
//         label: "Download here",
//       },
//     ];
//   }

  const BoxNotif = ({ value }) => {
    return (
      <div
        className="card card-custom card-stretch gutter-b"
        style={{ backgroundColor: "#1C325E", minHeight: "170px" }}
        key={value.id}
        title={value.title}
      >
        <div className="card-body d-flex ps-xl-15">
          <div className="m-0">
            <div className="position-relative h2 z-index-2 font-weight-bolder text-white mb-7">
              {value.title}
            </div>
            <div className="mb-3">
              <a
                href={value.link}
                className="btn btn-danger fw-bold me-2"
                target={"_blank"}
              >
                {value.id === 1 ? "Start Registration":"Download Here"}
              </a>
            </div>
          </div>
          <img
            src={Ava1}
            alt="icon"
            className="position-absolute mr-0 bottom-0 right-0 h-150px"
          />
        </div>
      </div>
    );
  };

//   const InfoPopUP = () => {
//     const MessagePopup = () => {
//       return (
//         <div className="info-notif">
//           <p className="font-italic mb-5">Dear all</p>
//           <p className="text-dark">
//             Please see the attachment below for
//             <span className="text-info ml-1">
//               Plan Course Form (PCF) Submission Odd Semester 2023/2024
//             </span>
//             :
//           </p>
//           <p className="font-weight-bolder">
//             {stdstatus.program.id === 1 ? "Bachelor Program" : "Master Program"}
//           </p>
//           <div className="">
//             <ol>
//               {notifArr.map((v, index) =>
//                 index !== 0 ? (
//                   <li key={index}>
//                     <a href={v.link} target="_blank">
//                       {v.title}
//                     </a>
//                   </li>
//                 ) : (
//                   ""
//                 )
//               )}
//             </ol>
//           </div>
//         </div>
//       );
//     };

//     openModal({
//       header: "Academic Information",
//       message: <MessagePopup />,
//     });
//   };

//   useEffect(() => {
//     if (ispopup) {
//       InfoPopUP();
//     }
//   }, []);

  return notifInfo.loading ? <LoadingBtn /> :
  notifInfo.message ? <AlertNotif message={notifInfo.message} />: 
  Object.values(notifInfo.data).length > 0 && (
    <div className="row">
      {notifInfo.data.map((v) =>
            exept !== v.id && (
              <div className={"col-lg-4 col-xxl-4"}>
                <BoxNotif value={v} />
              </div>
            )
          )}
    </div>
  );
}
