import React from "react";
import jwt_decode from "jwt-decode";
import "./Form.css";

export default function Form(props) {
  //const currSection = (props.data) ? props.data : null;
  const sectionCom = props.data ? props.data.section_com : [];
  //console.log(sectionCom);
  const hasData = sectionCom
  .filter(item => item.has_access === 1)
  .sort((a, b) => a.wg_com_id - b.wg_com_id);

  const dataNilai = props.dataNilai;
  const setDataNilai = props.setDataNilai;

  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  //const objNilai = {
  //    section_id: (props.data) ? props.data.section_id : "",
  //    studentid: (props.data) ? props.data.studentid : "",
  //    ths_id:(props.data) ? props.data.id : "",
  // }
  //const [postTT, setPostTT] = useState(objNilai);
  // Function to remove components with score of 0

  const updateScore = (wg_com_id, newScore) => {
   // const regex = /^[0-9\b]+$/; // Regex to allow only numbers
   // const regex = /^[0-9,.]*$/;
   const regex = /^([0-9]|[1-9][0-9]|100)$/;
    if (newScore.length < 4) {
      if (newScore === '' || regex.test(newScore)) {
        if ( newScore <= 100) {
          const index = dataNilai.findIndex(
            (item) => item.wg_com_id === wg_com_id
          );
          if (index !== -1 ) {
            const newArray = [...dataNilai];
            newArray[index].score = (newScore!=="")?parseInt(newScore):"";
            setDataNilai(newArray);
          }
        }
      }
    }
  };

  const getScoreByWgComId = (wg_com_id) => {
    const foundItem = dataNilai.find((item) => item.wg_com_id === wg_com_id);
    if (foundItem) {
      return foundItem.score;
    } else {
      return 0;
    }
  };

  return (
    <div id="form-thesis-score" className="p-10">
      <h3>
        <span className="font-weight-bolder text-dark">Score</span>
      </h3>
      <div className={"md-auto"}>
        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
          <tbody>
            {hasData.map((v, index) => (
              <tr key={index}>
                <td width={"8%"}>{index + 1}) </td>
                <td width={"40%"} className="font-weight-bolder">
                  {decodeURIComponent(v.name)}
                </td>
                <td>
                  <input
                    disabled={props.formDisabled}
                    type="text"
                    pattern="[0-9]*"
                    name="teaching-topic"
                    required
                    className="form-control required w-60px"
                    value={getScoreByWgComId(v.wg_com_id)}
                    onChange={(e) => updateScore(v.wg_com_id, e.target.value)}
                    
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
