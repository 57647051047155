import React, { useEffect, useMemo, useState } from "react";
import {
  CREATE_APPEND_SECTION_COM,
  DELETE_SECTION,
  GET_SCR_COM,
  GET_SECTION,
} from "./apis";
import CreatableSelect from "react-select/creatable";
import { ProgressBar } from "react-bootstrap";
import { openModal } from "../../../../../../Library/ModalPopUp";
import { AlertNotif } from "../../../../../../Library/AlertNotif";

const RowCompOri = ({
  data,
  scoreComp,
  setScoreComp,
  selectedThesis,
  setSelectedThesis,
  listComp,
  section_id,
  setDelCom,
  ReloadData,
  setListComp

}) => {

  const [loading, setLoading] = useState(false);
  const HandlerRemoveBtn = (setDelCom, ReloadData) => {
    setLoading(true);
    const paramPost = {
      deletetype: "com",
      reff_id: data.id,
      section_id: section_id,
    };

    //DELETE_SECTION(paramPost, setDelCom, ReloadData);

    openModal({ open: false })
  };
  const removeComItem = (id, wg_com_id, name) => {
    const newData = [...scoreComp];
    const removeData = newData.filter((item) => item.id !== id);
    setScoreComp(removeData);
    const componentThesis = [{ id: 29, name: "Colloqium", type:"Formative" }, { id: 30, name: "Thesis work", type:"Summative" }, { id: 31, name: "Defense", type:"Summative" }, { id: 32, name: "Paper draft", type:"Summative" },
      , { id: 48, name: "Colloqium (SOEST)", type:"Formative" }, { id: 49, name: "Thesis work (SOEST)", type:"Summative" }
      , { id: 50, name: "Defense (SOEST)", type:"Summative" }, { id: 51, name: "Paper draft (SOEST)", type:"Summative" } 
    ];       
    const filteredThesis = componentThesis.filter(thesis => {
            return !removeData.some(item => item.wg_com.id === thesis.id);
          });
          setListComp(filteredThesis);
    
    openModal({ open: false })
  };
  const MessageRemove = () => {
    return (
      <div className="text-center">
        <h3>
          Deleting this grade component will permanently delete the saved scores for this component. Are you sure you want to continue?
        </h3>
        <div className="mt-5 btn-group">
          <button className="btn btn-lg btn-light" type="button" onClick={() => openModal({ open: false })}>Cancel</button>
          <button
            className="btn btn-lg btn-danger px-6"
            type="button"
            disabled={loading}
            onClick={() => removeComItem(data.id,data.wg_com_id,data.name)}
          >
            <span className="font-weight-bolder">
              {loading ? "Processing" : "Delete Permanently"}
            </span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <tr key={data.id}>
      <td>
        {/* <div className="text-capitalize">
          {decodeURIComponent(data.wg_com_name)}
        </div> */}
        <input
          disabled
          type="text"
          maxLength={20}
          title="Maximum 20 characters"
          className="form-control"
          defaultValue={decodeURIComponent(data.wg_com_name)}
          onChange={(e) =>
            HandlerInputName(e.target.value, data.wg_com_id, setScoreComp)
          }
        />
        {/* <TextAutoCompleteCom
          id={data.id}
          name={decodeURIComponent(data.wg_com_name)}
          setScoreComp={setScoreComp} /> */}
      </td>
      <td>
        <div className="text-capitalize">{data.wg_com_type}</div>
      </td>
      <td>
        <div className="input-group w-150px">
          <input
            type="text"
            className="form-control text-center"
            value={data.weight * 100}
            onChange={(e) =>
              HandlerInputScore(e.target.value, data.wg_com_id, setScoreComp)
            }
          />
          <div className="input-group-append">
            <span className="input-group-text bg-white">%</span>
            <span
              className="input-group-text bg-light-danger cursor-pointer"
              onClick={() =>
                openModal({
                  header: "Confirmation",
                  message: (
                    <MessageRemove />
                  ),
                })
              }
            >
              <i className="fas fa-trash-alt text-danger"></i>
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

const RowCompNew = ({ data, scoreComp, setScoreComp, selectedThesis, setSelectedThesis, listComp }) => {
  //console.log(data);
  //console.log(filteredItems);
  const removeComItem = (id, wg_com_id) => {
    const newData = [...scoreComp];
    const removeData = newData.filter((item) => item.id !== id);
    setScoreComp(removeData);
    const newSelected = [...selectedThesis];
    const removeSelected = newSelected.filter((item) => item.id !== wg_com_id);
    setSelectedThesis(removeSelected);
  };
  return (
    <tr key={data.id} className="bg-light">
      <td>
        <select
          name="com_type"
          className="form-control"
          defaultValue={data.wg_com_id}
          onChange={(e) =>
            HandlerNameScore(
              e,
              data.wg_com_id,
              scoreComp,
              setScoreComp,
              selectedThesis,
              setSelectedThesis
            )
          }
        >
          <option value="">Select a grading component</option>
          {
            (Object.keys(listComp).length > 0) ?
              listComp.map((thesis) => (
                <option key={thesis.id} value={thesis.id} data-name={thesis.name} data-type={thesis.type}>
                  {thesis.name}
                </option>
              ))
              : ""
          }
        </select>
      </td>
      <td>
        <div className="text-capitalize">{data.wg_com_type}</div>
      </td>
      <td>
        <div className="input-group w-150px">
          <input
            type="text"
            className="form-control text-center"
            value={data.weight * 100}
            onChange={(e) =>
              HandlerInputScore(e.target.value, data.wg_com_id, setScoreComp)
            }
          />
          <div className="input-group-append">
            <span className="input-group-text bg-white">%</span>
            <span
              className="input-group-text bg-light-danger cursor-pointer"
              onClick={() => removeComItem(data.id, data.wg_com_id)}
            >
              <i className="fas fa-trash-alt text-danger"></i>
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

const TextAutoCompleteCom = ({ id, name, setScoreComp }) => {
  const [component, setComponent] = useState({
    loading: false,
    data: [],
    message: "",
  });
  useEffect(() => {
    const param_scr = { name: "" };
    GET_SCR_COM(param_scr, setComponent);
  }, []);

  const [options, setOptions] = useState([]);
  let selectedData = null;
  if (name) {
    selectedData = { value: id, label: name };
  }
  const [selectedOption, setSelectedOption] = useState(selectedData);

  const ResultData = useMemo(() => {
    let computedData = [];
    if (Object.values(component.data).length > 0) {
      computedData = component.data.map((v) => {
        var obj = {};
        obj.value = v.id;
        obj.label = decodeURIComponent(v.name);
        return obj;
      });

      setOptions(computedData);
    }
    return computedData;
  }, [component.data]);

  const handleChange = (selectedOption) => {
    console.log(id, selectedOption);
    setSelectedOption(selectedOption);
    setScoreComp((currentItems) =>
      currentItems.map((item) =>
        item.id === id
          ? { ...item, wg_com: { ...item.wg_com, name: selectedOption.label } }
          : item
      )
    );
  };

  const handleCreate = (inputValue) => {
    const newValue = { value: id, label: inputValue };
    setOptions([...options, newValue]);
    setSelectedOption(newValue);
    setScoreComp((currentItems) =>
      currentItems.map((item) =>
        item.wg_com_id === id
          ? { ...item, wg_com: { ...item.wg_com, name: inputValue } }
          : item
      )
    );
  };

  return (
    <div>
      <CreatableSelect
        isSearchable
        onChange={handleChange}
        onCreateOption={handleCreate}
        options={options}
        value={selectedOption}
        placeholder="Type or select an option..."
      />
    </div>
  );
};

const HandlerInputScore = (value, id, setScoreComp) => {
  const nWeight = parseFloat(value) / 100 || 0;
  setScoreComp((currentItems) =>
    currentItems.map((item) =>
      item.wg_com_id === id ? { ...item, weight: nWeight } : item
    )
  );
};

const getNextId = (type, components) => {
  const range =
    type === "Formative" ? { min: 1, max: 14 } : { min: 15, max: 29 };
  const usedIds = components
    .filter((comp) => comp.wg_com.type === type)
    .map((comp) => comp.wg_com_id)
    .sort((a, b) => a - b);

  for (let id = range.min; id <= range.max; id++) {
    if (!usedIds.includes(id)) {
      return id;
    }
  }
  return null;
};

const getNextIdThesis = (type, components) => {
  const range =
    type === "Formative" ? { min: 29, max: 32 } : { min: 15, max: 29 };
  const usedIds = components
    .filter((comp) => comp.wg_com.type === type)
    .map((comp) => comp.wg_com_id)
    .sort((a, b) => a - b);

  for (let id = range.min; id <= range.max; id++) {
    if (!usedIds.includes(id)) {
      return id;
    }
  }
  return null;
};

const HandlerTypeScore = (value, id, scoreComp, setScoreComp) => {
  const com_id = getNextId(value, scoreComp);
  if (com_id === null) {
    alert("Has exceeded the maximum limit");
    return;
  } else {
    setScoreComp((currentItems) =>
      currentItems.map((item) =>
        item.wg_com_id === id
          ? {
            ...item,
            wg_com: { ...item.wg_com, type: value, id: com_id },
            wg_com_id: com_id,
          }
          : item
      )
    );
  }
};

const HandlerNameScore = (event, id, scoreComp, setScoreComp, selectedThesis, setSelectedThesis) => {
  const selectedOption = event.target.options[event.target.selectedIndex];
  const com_id = selectedOption.value;
  const name = selectedOption.getAttribute('data-name');
  const type = selectedOption.getAttribute('data-type');
  const newArray = scoreComp.map(item => {
    return {
      id: item.wg_com_id,
      name: item.name
    };
  });

  // Function to add a new object to the newItems state
  const addNewObject = (com_id, name) => {
    const objNew = {
      id: parseInt(com_id),
      name: name,
    };

    const updatedNewItems = [...newArray, objNew];
    setSelectedThesis(updatedNewItems);
  };

  addNewObject(parseInt(com_id), name)
  //setSelectedThesis([...selectedThesis, newArray]);

  if (com_id === null || com_id === "") {
    alert("Has exceeded the maximum limit");
    return;
  } else {
    setScoreComp((currentItems) =>
      currentItems.map((item) =>
        item.wg_com_id === id
          ? {
            ...item,
            name: name,
            wg_com: { ...item.wg_com, type: type, id: parseInt(com_id) },
            wg_com_id: parseInt(com_id),
          }
          : item
      )
    );

  }
};
const HandlerInputName = (value, id, setScoreComp) => {
  setScoreComp((currentItems) =>
    currentItems.map((item) =>
      item.wg_com_id === id ? { ...item, name: encodeURI(value) } : item
    )
  );
};



const HandlerRemoveComp = (data, section_id, setDelCom, ReloadData) => {
  openModal({
    header: "Confirmation",
    message: (
      <MessageRemove
        data={data}
        section_id={section_id}
        setDelCom={setDelCom}
        ReloadData={ReloadData}
      />
    ),
  });
};

const BtnAddNewComponents = ({ scoreComp, setScoreComp, listComp, setListComp, selectedThesis }) => {
  const existingIds = scoreComp.map((item) => item.id);
  const numID = GenerateNumID(existingIds);
  const handlerClick = () => {
    const updatedComponentList = listComp.filter(item2 => {
      return !selectedThesis.some(item1 => item1.id === item2.id);
    });
    setListComp(updatedComponentList);

    const updatedItems = removeOriFalse(scoreComp);
    setScoreComp(updatedItems);
    const objNew = {
      id: numID,
      weight: 0,
      wg_com_id: numID,
      name: "",
      wg_com: { id: numID, type: "" },
      ori: false,
    };
    //setScoreComp([...scoreComp, objNew]);
    setScoreComp((scoreComp) => [...scoreComp, objNew]);
  };
  return (
    <button
      className="btn btn-sm btn-light font-weight-bolder"
      type="button"
      onClick={() => handlerClick()}
    >
      <i className="fa fa-plus-circle"></i>
      Add new component
    </button>
  );
};


const removeOriFalse = (array) => {
  return array.map(item => {
    if (item.hasOwnProperty('ori') && item.ori === false) {
      const { ori, ...rest } = item;
      return rest;
    }
    return item;
  });
};

const GenerateNumID = (existingIds) => {
  const maxId = 1000;
  let uniqueId;
  do {
    uniqueId = Math.floor(Math.random() * (maxId - 28)) + 29;
  } while (existingIds.includes(uniqueId));
  return uniqueId;
};

const ItemComWeight = ({ data, myComponent, setMyComponent }) => {
  return (
    <div className="input-group w-150px">
      <input
        type="text"
        className="form-control text-center"
        value={data.weight * 100}
        onChange={(e) =>
          handleInputChange(data.wg_com_id, e.target.value, setMyComponent)
        }
      />
      <div className="input-group-append">
        <span className="input-group-text bg-white">%</span>
        <span
          className="input-group-text bg-light-danger cursor-pointer"
          onClick={() => HandlerRemoveItem(data, myComponent, setMyComponent)}
        >
          <i className="fas fa-trash-alt text-danger"></i>
        </span>
      </div>
    </div>
  );
};

const ItemWeight = ({ data, DELETE_SECTION, section_id }) => {
  return (
    <div className="d-flex align-items-center">
      <span className="fw-bolder mr-5">{data.weight * 100}%</span>

      <button
        onClick={() => HandlerRemoveCom(data, DELETE_SECTION, section_id)}
        title={"Remove component"}
        className="btn btn-sm btn-icon btn-light"
      >
        <i className="fas fa-trash-alt text-danger"></i>
      </button>
    </div>
  );
};

const HandlerRemoveItem = (data, myComponent, setMyComponent) => {
  let updatedComponents = [...myComponent];
  const removeItem = updatedComponents.filter(
    (prev) => prev.id !== data.wg_com_id
  );
  setMyComponent(removeItem);
};

const handleInputChange = (id, value, setMyComponent) => {
  const nWeight = parseFloat(value) / 100 || 0;
  setMyComponent((currentItems) =>
    currentItems.map((item) =>
      item.wg_com_id === id ? { ...item, weight: nWeight } : item
    )
  );
};

const HandlerAddItem = (data, setMyComponent, scoreComp) => {
  const existingIds = scoreComp.map((item) => item.id);
  const numID = GenerateNumID(existingIds);
  const myItem = {
    id: numID,
    wg_com: { id: data.id, name: data.name },
    wg_com_id: data.id,
    weight: 0,
    ori: false,
    is_import: true,
  };
  setMyComponent((prev) => [...prev, myItem]);
};

const HandlerRemoveCom = (data, DELETE_SECTION, section_id) => {
  if (window.confirm("Are you sure want to remove this item ?")) {
    const paramPost = {
      deletetype: "com",
      reff_id: data.id,
      section_id: section_id,
    };
    DELETE_SECTION(paramPost);
  }
};

const ProgressScoreGenerate = ({ start, setProgressBar }) => {
  const [progress, setProgress] = useState(0);

  const simulateProgress = () => {
    const increaseAmount = 100 / 50; // 5detik
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + increaseAmount;
          return newProgress >= 100 ? 100 : newProgress;
        });
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        clearInterval(interval);
        setProgressBar(false);
      }
    }, 1000);
  };

  if (start) {
    simulateProgress();
  }

  return <ProgressBar now={progress} label={`${progress}%`} />;
};

const FormSingleComponentUpdate = ({
  data,
  section_id,
  HandlerProgressBar,
}) => {
  const [section, setSection] = useState({
    loading: false,
    data: [],
    message: "",
  });
  useEffect(() => {
    GET_SECTION({ section_id: section_id }, setSection);
  }, []);

  const [totalWeight, setTotalWeight] = useState(0);

  const ResultData = useMemo(() => {
    const computedData = section.data;
    if (Object.values(section.data).length > 0) {
      const scoreComp =
        Object.values(section.data.section_com).length > 0
          ? section.data.section_com
          : [];
      if (Object.values(scoreComp).length > 0) {
        const tWeight = scoreComp.reduce(
          (acc, obj) => acc + obj.weight * 100,
          0
        );
        setTotalWeight(tWeight);
      }
    }
    return computedData;
  }, [section.data, totalWeight]);

  const objParam = { ...data };
  const [postData, setPostData] = useState(objParam);
  const [msg, setMsg] = useState("");
  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const handlerSubmit = (e) => {
    e.preventDefault();
    if (postData.name !== "" || postData.weight !== 0) {
      const section_com = section.data.section_com;
      const getALlCompWithoutCurrID = section_com.filter(
        (prev) => prev.wg_com_id !== postData.wg_com_id
      );
      let arrComponent = getALlCompWithoutCurrID.map((v) => {
        var obj = {};
        obj.weight = v.weight * 100;
        obj.name = encodeURIComponent(v.name);
        obj.type = v.wg_com.type;
        obj.wg_com_id = v.wg_com_id;
        return obj;
      });
      arrComponent.push({
        weight: postData.weight * 100,
        name: encodeURIComponent(postData.name),
        type: postData.wg_com.type,
        wg_com_id: postData.wg_com_id,
      });

      const paramObj = { component: arrComponent, section_id: section_id };
      CREATE_APPEND_SECTION_COM(paramObj, setSubmit, HandlerProgressBar, true);
    } else {
      alert("Please fill up the form with correctly.");
    }
  };
  const [isRemove, setIsRemove] = useState(false);
  const [weightEqual, setWeightEqual] = useState({ msg: "", disabled: false });

  const handlerWeight = (value) => {
    const findCompByID = section.data.section_com.filter(
      (item) => item.wg_com_id !== data.wg_com_id
    );
    const totalW = findCompByID.reduce((acc, obj) => acc + obj.weight * 100, 0);
    const nWeight = parseFloat(value) / 100 || 0;
    const currentWeight = totalW + nWeight * 100;
    if (currentWeight !== 100) {
      setWeightEqual({
        msg: "The sum of all weights must be equal to 100%",
        disabled: true,
      });
    } else {
      setWeightEqual({ msg: "", disabled: false });
      setPostData({
        ...postData,
        weight: nWeight,
      });
    }
  };

  return (
    <form autoComplete="off" method="post" onSubmit={(e) => handlerSubmit(e)}>
      <div className="form-group">
        <label className="required font-weight-bolder">
          Grade Component Name
        </label>
        <input
          type="text"
          className="form-control"
          defaultValue={decodeURIComponent(postData.name)}
          onChange={(e) =>
            setPostData({
              ...postData,
              name: encodeURIComponent(e.target.value),
            })
          }
        />
      </div>
      {submit.message ? (
        <div className="my-5">
          <AlertNotif color={"danger"} message={submit.message} />
        </div>
      ) : (
        ""
      )}

      {weightEqual.msg ? (
        <div className="my-5">
          <AlertNotif color={"danger"} message={weightEqual.msg} />
        </div>
      ) : (
        ""
      )}
      <div className="text-right">
        <div className="btn-group">
          <button
            className="btn btn-lg btn-light"
            type="button"
            onClick={() => openModal({ open: false })}
          >
            Cancel
          </button>
          <button
            className="btn btn-lg btn-primary px-5 font-weight-bolder"
            disabled={submit.loading}
            type="submit"
          >
            {submit.loading ? "Processing..." : "Save Changes"}
          </button>
        </div>
      </div>
    </form>
  );
};

export {
  ItemWeight,
  ItemComWeight,
  HandlerAddItem,
  RowCompOri,
  RowCompNew,
  BtnAddNewComponents,
  ProgressScoreGenerate,
  FormSingleComponentUpdate,
};
