import React, { useEffect, useRef, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import "./activity.css";
import Card from "../../../Lecturer/Thesis/Activity/components/Card";
import CardCircular from "../../../Lecturer/Thesis/Activity/components/CardCircular";
import AdvisorNote from "../../../Lecturer/Thesis/Activity/components/AdvisorNote";
import Rejected from "./components/Rejected";
import Approved from "./components/Approved";
import Waiting from "./components/Waiting";
import Attachment from "./components/Attachment";
import {
  dataFetcher,
  formatDate,
  safeDecode,
} from "../../../Lecturer/Thesis/Activity/components/helper";
import ModalNewActivity from "./components/ModalNewActivity";
import Tooltip from "../../../Lecturer/Thesis/Activity/components/Tooltip";
import NoData from "../../../Lecturer/Thesis/Activity/components/NoData";
import cetakPDF from "./components/cetakPDF";
import ExportPDF from "./components/ExportPDF";
import Loading from "../../../Lecturer/Thesis/Activity/components/Loading";

const Activity = () => {
  const [openDialogNote, setOpenDialogNote] = useState(-1);
  const [datas, setDatas] = useState({});
  const [optionsAdvisor, setOptionsAdvisors] = useState([]);
  const [logDatas, setLogDatas] = useState([]);
  const [progressCircularCount, setProgressCircularCount] = useState({
    adv: 0,
    co: 0,
  });
  const [openNewActivityModal, setOpenNewActivityModal] = useState(false);
  const [loadingExportLog, setLoadingExportLog] = useState(false);
  const refTable = useRef(null);

  const [isPrint, setIsPrint] = useState({ state: false, data: {} });
  const [allowPrint, setAllowPrint] = useState(false);
  const [activeCalendar, setActiveCalendar] = useState([]);
  const [counterIsreadyForLais, setCounterIsreadyForLais] = useState({
    advisor: 0,
    coadvisor: 0,
  });

  const [laisMandatoryData, setLaisMandatoryData] = useState({});

  const [isAllowtoSubmitActivity, setIsAllowtoSubmitActivity] = useState(false);

  // remote student 11909005
  //   riki.hermansyah@student.sgu.ac.id
  //   N1h0ng080$$

  // get thesis by student
  const getStudent = async () => {
    setLogDatas({ loading: true, data: [], message: "" });
    setDatas({ loading: true, data: {}, message: "" });

    const active = await dataFetcher({
      target_data: "GET_ACTIVE_ACAD",
    });

    const body = {
      target_data: "GET_THS_BY_STUDENT",
    };
    const response = await dataFetcher(body);
    console.log("respond std", response);
    setDatas(response);
    if (response.message === "") {
      const results = response.data;

      // acad
      if (Object.keys(results).length > 0 && active.data.length > 0) {
        results.course_detail.map((yy) => {
          const activeCourse = active.data.find((ac) => {
            if (ac.atp_id_term === yy.atp_term.id) {
              setLaisMandatoryData({
                cm_course_id: yy?.course?.id,
                course_title: yy?.course?.coursetitle,
                atp_id_term: yy?.atp_term?.id,
              });
              return yy;
            }
            // return ac.atp_id_term === yy.atp_term.id;
          });
          console.log("activeCourse", activeCourse);
          if (activeCourse) {
            setIsAllowtoSubmitActivity(true);
          }
        });
      }

      // advisor
      if (results.advisor) {
        if (results.coadvisor === null) {
          setOptionsAdvisors([
            {
              value: {
                name: results.advisor.fullname,
                email: results.advisor.email,
                role: "advisor",
                emp_id: results.advisor.id,
              },
              label: results.advisor.fullname,
            },
          ]);
        } else {
          setOptionsAdvisors([
            {
              value: {
                name: results.advisor.fullname,
                email: results.advisor.email,
                role: "advisor",
                emp_id: results.advisor.id,
              },
              label: results.advisor.fullname,
            },
            {
              value: {
                name: results.coadvisor.fullname,
                email: results.coadvisor.email,
                role: "coadvisor",
                emp_id: results.coadvisor.id,
              },
              label: results.coadvisor.fullname,
            },
          ]);
        }
        getLog(results);
      } else {
        setIsAllowtoSubmitActivity(false)
        setLogDatas({ loading: false, data: [], message: "No record found" });
      }
    }
  };

  // get log
  const getLog = async (resX) => {
    setLogDatas({ loading: true, data: [], message: "" });

    const body = {
      parameters: {
        type: "act.type.thesis.activity",
        user: resX.studentdata.sgumail ?? datas.data.studentdata.sgumail,
      },
      target_data: "GET_ACTIVITY_BY_OWNER",
    };
    const response = await dataFetcher(body);
    console.log(response);
    const reverse = response.data.reverse();
    setLogDatas({
      loading: response.loading,
      data: reverse,
      message: response.message,
    });

    if (response.message === "") {
      const results = response.data;

      const adv = results.filter(
        (z) =>
          z.status === "APPROVED" && z.target_user.email === resX.advisor.email
      );
      const co =
        resX.coadvisor !== null
          ? results.filter(
              (z) =>
                z.status === "APPROVED" &&
                z.target_user.email === resX.coadvisor.email
            )
          : [];
      setProgressCircularCount({ adv: adv.length, co: co.length });

      // tambah untuk cek ready for lais
      const adv_waiting = results.filter(
        (z) =>
          z.status === "SUBMITTED" && z.target_user.email === resX.advisor.email
      );

      const co_waiting =
        resX.coadvisor !== null
          ? results.filter(
              (z) =>
                z.status === "SUBMITTED" &&
                z.target_user.email === resX.coadvisor.email
            )
          : [];

      setCounterIsreadyForLais({
        advisor: adv.length + adv_waiting.length,
        coadvisor: co.length + co_waiting.length,
      });
    }
  };

  const limitation = (array) => {
    let result = [];
    let currentSum = 0;
    let startIndex = 0;
    let lastIndex = 0;

    array.forEach((value, index) => {
      if (currentSum + value.descr.length > 1750) {
        // Simpan currentSum dan index1, index2 jika ada range
        result.push({
          char: currentSum,
          index1: startIndex,
          index2: index - 1 !== startIndex ? index - 1 : startIndex, // Hanya tambahkan index2 jika ada range
        });

        // Reset currentSum dan set startIndex ke index saat ini
        currentSum = value.descr.length;
        startIndex = index;
      } else {
        // Tambahkan value.descr ke currentSum
        currentSum += value.descr.length;
      }

      lastIndex = index;
    });

    // Simpan currentSum terakhir jika ada
    if (currentSum > 0) {
      result.push({
        char: currentSum,
        index1: startIndex,
        index2: lastIndex,
      });
    }

    console.log(result);
    return result;
  };

  // generate pdf
  const generatePdf = async () => {
    if (logDatas.message === "") {
      setLoadingExportLog(true);
      const newLog = logDatas.data.filter((x) => x.status === "APPROVED");
      if (newLog.length > 0) {
        // set limitation per page
        const limit = limitation(newLog);
        // end
        cetakPDF(newLog, datas.data, limit);
        setTimeout(() => {
          setLoadingExportLog(false);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    // getActiveAcad();
    getStudent();
  }, []);

  useEffect(() => {
    if (logDatas.message === "") {
      setAllowPrint(logDatas.data.some((x) => x.status === "APPROVED"));
      const newLog = logDatas.data.filter((x) => x.status === "APPROVED");
      setIsPrint({ state: false, data: newLog });
    }
  }, [logDatas]);


  return (
    <div>
      <h1 className="mb-10">Thesis Activity</h1>
      {datas.loading === true ? (
        <Loading />
      ) : datas.message === "" ? (
        <>
          <div className="row mb-5">
            <div className="col-xl-6 mb-sm-5">
              <div className="row h-100">
                <div className="col-12 mb-5">
                  <Card
                    title="Thesis title"
                    name={datas?.data?.final_title}
                    icon="t"
                  />
                </div>
                <div className="col-6">
                  <Card
                    title="Advisor"
                    name={datas?.data?.advisor?.fullname}
                    icon="a"
                  />
                </div>
                <div className="col-6">
                  <Card
                    title="Co-Advisor"
                    name={
                      datas?.data?.coadvisor?.fullname === undefined
                        ? "-"
                        : datas?.data?.coadvisor?.fullname
                    }
                    icon="a"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="row h-100">
                <div className="col-6">
                  <CardCircular
                    isCoExist={datas?.data?.coadvisor?.fullname !== undefined}
                    title="Advisory Meetings with Advisor"
                    meet={progressCircularCount.adv}
                  />
                </div>
                <div className="col-6">
                  <CardCircular
                    title="Advisory Meetings with Co-Advisor"
                    meet={
                      datas?.data?.coadvisor?.fullname === undefined
                        ? -1
                        : progressCircularCount.co
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <section className=" mb-10 bg-white mx-1 p-5 rounded">
            <div className="d-flex justify-content-between align-items-center my-5">
              <h2 style={{ fontFamily: "'Roboto', sans-serif" }}>
                Advisory Log
              </h2>
              <div className="d-flex">
                <div className="mr-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ width: 150, fontFamily: "'Roboto', sans-serif" }}
                    onClick={() => setOpenNewActivityModal(true)}
                    disabled={!isAllowtoSubmitActivity}
                  >
                    New Activity
                  </button>
                  <div
                    className="modal fade"
                    tabIndex={-1}
                    id="modal_add_new_record"
                  ></div>
                </div>
                <button
                  type="button"
                  title={!allowPrint && "No approved log yet!"}
                  style={{ width: 150, fontFamily: "'Roboto', sans-serif" }}
                  className="btn bg-gray-200 text-gray-700 bg-hover-primary text-hover-white"
                  onClick={() => allowPrint && generatePdf()}
                >
                  {loadingExportLog ? "Exporting..." : "Export Log"}
                </button>
              </div>
            </div>
            {/* table */}
            {logDatas.loading ? (
              <Loading />
            ) : logDatas.message === "" ? (
              <div
                className="px-10 border-bottom border-b-2 mt-10"
                ref={refTable}
              >
                <table
                  className="table table-row-bordered table-row-gray-300"
                  style={{ fontFamily: "'Roboto', sans-serif" }}
                >
                  <thead>
                    <tr className="text-gray-800">
                      <th
                        className="text-muted ps-5"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        NO
                      </th>
                      <th
                        className="text-muted"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        ADVISORY DATE
                      </th>
                      <th
                        className="text-muted"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        ACTIVITY DESCRIPTION
                      </th>
                      <th
                        className="text-muted text-center"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        ATTACHMENT
                      </th>
                      <th
                        className="text-muted text-center"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        ADVISOR
                      </th>
                      <th
                        className="text-muted text-center"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        REMARK
                      </th>
                      <th
                        className="text-muted text-center"
                        style={{ letterSpacing: "0.05rem" }}
                      >
                        NOTES
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {logDatas.data.map((log, index) => (
                      <tr key={index}>
                        <td
                          className="text-center"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Roboto', sans-serif",
                            // paddingRight: 50,
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="text-nowrap"
                          style={{
                            fontSize: 14,
                            paddingRight: 50,
                            width: 100,
                          }}
                        >
                          {formatDate(log.act_date)}
                        </td>
                        <td
                          style={{
                            fontSize: 14,
                            whiteSpace: "pre-line",
                            fontFamily: "'Roboto', sans-serif",
                            // paddingRight: 25,
                            width: 600,
                          }}
                        >
                          {log.descr}
                        </td>
                        <td
                          className="text-center"
                          style={{ paddingLeft: 25, paddingRight: 25 }}
                        >
                          {log.file_ref === null ? "-" : <Attachment />}
                        </td>
                        <td
                          className="text-nowrap text-center"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Roboto', sans-serif",
                            paddingLeft: 25,
                            paddingRight: 25,
                          }}
                        >
                          {log.target_user.fullname}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Roboto', sans-serif",
                            paddingLeft: 25,
                            paddingRight: 25,
                          }}
                        >
                          {log.status === "APPROVED" && <Approved />}
                          {log.status === "REJECTED" && <Rejected />}
                          {log.status === "SUBMITTED" && <Waiting />}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            fontSize: 14,
                            fontFamily: "'Roboto', sans-serif",
                            paddingLeft: 25,
                            paddingRight: 25,
                          }}
                        >
                          {log.remark !== "" && log.remark !== null ? (
                            <>
                              <Tooltip title="See Notes">
                                <i
                                  className="icon-lg text-primary flaticon2-paper cursor-pointer"
                                  onClick={() => setOpenDialogNote(index)}
                                ></i>
                              </Tooltip>
                              {openDialogNote === index && (
                                <AdvisorNote
                                  note={log.remark}
                                  close={setOpenDialogNote}
                                />
                              )}
                            </>
                          ) : (
                            <p>-</p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="mt-10">
                <NoData msg={logDatas.message} sbj="" />
              </div>
            )}

            {openNewActivityModal && (
              <ModalNewActivity
                closeModal={setOpenNewActivityModal}
                getLog={getLog}
                optionsAdvisor={optionsAdvisor}
                datas={datas}
                counterLais={counterIsreadyForLais}
                laisMandatoryData={laisMandatoryData}
              />
            )}
          </section>
          {isPrint.state && <ExportPDF data={isPrint.data} bio={datas.data} />}
        </>
      ) : (
        <NoData msg={datas.message} sbj="" />
      )}
    </div>
  );
};

export default Activity;
