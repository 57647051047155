import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../../../Library/AxiosLibWithHeader2";
import { AlertNotif } from "../../../../../../Library/AlertNotif";
import LoadingBtn from "../../../../../Widgets/LoadingBtn";

export default function CardClassList({ setSectiondata, reloadAwal, reload }) {
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [atdLctAct, setAtdLctAct] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const Call_GET_ATD_LECTURER_BY_ACTIVECAL_M = () => {
    setAtdLctAct({ message: "", data: [], loading: true });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: {
        empid: AuthDecode.currentData.entityid,
      },
      target_data: "GET_ATD_LECTURER_BY_ACTIVECAL",
    };
    const postData = {
      keys: keys,
      body: body,
    };
    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setAtdLctAct({
          data: [],
          message: response.error,
          loading: false,
        });
      } else if ("message" in response.response) {
        var msg = response.response.message.user;
        setAtdLctAct({
          data: [],
          message: msg,
          loading: false,
        });
      } else {
        var results = response.response.data;
        if (results) {
          const only_thesis = results.filter(
            (item) => item.course.is_thesis === true
          );
          setAtdLctAct({
            data: only_thesis,
            message: "",
            loading: false,
          });
        } else {
          setAtdLctAct({
            data: [],
            message: "No record found",
            loading: false,
          });
        }
      }
    });
  };

  const upSection = (results) => {
    setSectiondata((prevData) => ({
      ...prevData,
      message: "",
      data: [...prevData.data, results],
      loading: false,
    }));
  };

  useEffect(() => {
    Call_GET_ATD_LECTURER_BY_ACTIVECAL_M();
    reloadAwal(0);
  }, [reload]);

  const totalComponent = (data) => {
    let totalWeight = 0;
    if (Object.values(data).length > 0) {
      totalWeight = Math.round((data.reduce((sum, l) => sum + (l.weight || 0), 0)) * 100) / 100; 
    }

    return (
      <>
        {parseInt(totalWeight * 100) < 100 ? (
          <div className="border border-danger m-2 rounded p-2">
            <div className="d-flex flex-column flex-grow-1">
              <span className="text-danger font-weight-bold font-size-sm mb-1">
                Grading under{" "}
                <i className="la la-times-circle icon-1x text-danger"></i>
              </span>
              <span className="font-weight-bolder font-size-sm mb-1 text-center">
                {" "}
                {totalWeight * 100}%
              </span>
            </div>
          </div>
        ) : (
          <div className="border border-info m-2 rounded p-2">
            <div className="d-flex flex-column flex-grow-1">
              <span className="text-info font-weight-bold font-size-sm mb-1">
                Grading <i className="la la-check-circle icon-1x text-info"></i>
              </span>
              <span className="font-weight-bolder font-size-sm mb-1 text-center">
                {" "}
                {totalWeight * 100}%
              </span>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            List of Sections
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            List of your active class
          </span>
        </h3>
        <div className="card-toolbar">
          <button className="btn btn-sm btn-clear" type="button">
            Total Item {Object.values(atdLctAct.data).length}
          </button>
        </div>
      </div>
      <div className="card-body py-3">
        <div className="table-responsive">
          <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
            <thead>
              <tr className="text-left text-uppercase">
                <th width="5%">No</th>
                <th width="10%">Section ID</th>
                <th width="20%">Course</th>
                <th width="20%">Department</th>
                <th>Grading Scheme</th>
                <th>Detail</th>
              </tr>
            </thead>
            <tbody>
              {atdLctAct.loading ? (
                <tr>
                  <td colSpan={6}>
                    <LoadingBtn />
                  </td>
                </tr>
              ) : atdLctAct.message ? (
                <tr>
                  <td colSpan={6}>
                    <AlertNotif color="danger" message={atdLctAct.message} />
                  </td>
                </tr>
              ) : Object.values(atdLctAct.data).length > 0 ? (
                atdLctAct.data.map((v, index) => (
                  <tr key={index}>
                    <td>
                      <div className="symbol symbol-50 symbol-light mr-1">
                        <span className="symbol-label">
                          <span className="h-50 align-self-center">
                            {index + 1}
                          </span>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-start flex-column">
                        <span className="font-weight-bolder text-primary mb-1 font-size-lg">
                          {v.section_id}
                        </span>
                        <div>
                          <span className="font-weight-bolder">
                            Total Session:{" "}
                            <span className="text-dark-50 ">
                              {v.section_session}
                            </span>
                          </span>
                          <span className="font-weight-bolder d-block">
                            Max seat:{" "}
                            <span className="text-dark-50 ">{v.max_seat}</span>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      {v.course ? (
                        <div className="d-flex justify-content-start flex-column">
                          <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                            {v.course.coursetitle}
                          </span>
                          <span className="text-dark-50 font-weight-bold d-block">
                            {v.course.code}
                          </span>
                          <span className="text-dark-50 font-weight-bold d-block">
                            Credit {v.course.credit}
                          </span>
                        </div>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      <div className="d-flex justify-content-start flex-column">
                        {v.corporate ? (
                          <span className="label label-lg label-light-danger label-inline">
                            {v.corporate.name}
                          </span>
                        ) : (
                          ""
                        )}
                        <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                          {v.cost_center ? v.cost_center.name : "-"}
                        </span>
                        <span className="text-dark-50 font-weight-bold d-block">
                          Term {v.atp_term ? v.atp_term.name : "-"}
                        </span>
                      </div>
                    </td>
                    <td>
                      {v.section_com ? (
                        <div className="d-flex flex-wrap">
                          {v.section_com.map((l, index) => (
                            <div
                              className="border border-primary m-2 rounded p-2"
                              key={index}
                            >
                              <div className="d-flex flex-column flex-grow-1">
                                <span className="text-primary font-weight-bold font-size-sm mb-1">
                                  {l.id ? decodeURIComponent(l.name) : "-"}
                                </span>
                                <span className="font-weight-bolder font-size-sm mb-1 text-center">
                                  {" "}
                                  {l.id ? l.weight * 100 + "%" : "-"}
                                </span>
                              </div>
                            </div>
                          ))}
                          {totalComponent(v.section_com)}
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <button
                        className="btn btn-icon btn-light btn-sm"
                        type="button"
                        onClick={(e) => upSection(v)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <polygon points="0 0 24 0 24 24 0 24"></polygon>
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                x="11"
                                y="5"
                                width="2"
                                height="14"
                                rx="1"
                              ></rect>
                              <path
                                d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>No data available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
