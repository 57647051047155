import React from "react";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import { AlertNotif } from "../../Library/AlertNotif";
import LoadingBtn from "../../Page/Widgets/LoadingBtn";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

const CardUnregis = () => {
  return (
    <div className="m-auto">
      <AlertNotif
        title="Your account is unregistered"
        message="Please contact IST to sync your account into MY SGU Portal"
        color="info"
      />
      <p className="text-center">
        <Link to={{ pathname: "/sign-out" }} className="btn btn-lg btn-primary">
          <i className="fs-1 fas fa-sign-out-alt"></i>
          <span className="font-weight-bolder">Re-Sign in here</span>
        </Link>
      </p>
    </div>
  );
};

const CardSessionTimeout = () => {
  return (
    <div>
      <AlertNotif
        title="You've been signed out"
        message="Please sign in again to continue using MY SGU Portal"
        color="danger"
      />
      <p className="text-center">
        <Link to={{ pathname: "/sign-out" }} className="btn btn-lg btn-primary">
          <i className="fs-1 fas fa-sign-out-alt"></i>
          <span className="font-weight-bolder">Re-Sign in here</span>
        </Link>
      </p>
    </div>
  );
};

const CardLais = ({ url, title }) => {
  return (
    <div className="card card-custom gutter-b shadow">
      <div className="card-body">
        <p className="text-center font-weight-bold h3">
          Hi, <span className="text-uppercase">{AuthDecode.currentData.given_name}</span>
        </p>
        <p className="text-center font-weight-bold h3">
          You are being redirected to the
          <span className="font-italic ml-2">
            <span className="text-danger font-weight-bolder">
              {title} Survey
            </span>{" "}
            Filling Page.
          </span>
          <br />
          <br />
          <div className="d-flex align-items-center justify-content-center">
            <LoadingBtn />
            <span className="ml-1 text-primary font-italic">
              Redirecting...
            </span>
          </div>
          <a
            href={url}
            className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 d-none"
          >
            Go to Survey
          </a>
        </p>
      </div>
    </div>
  );
};

export { CardUnregis, CardSessionTimeout, CardLais };
