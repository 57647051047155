import React from "react";

const TypeFailIDX = (num) => {
  var lable = "";
  switch (num) {
    case 1:
      lable = "Fail Score";
      break;
    case 2:
      lable = "Fail Attendance";
      break;
    case 3:
      lable = "Fail Cheating";
      break;
    case 9:
      lable = "Failed Score and Attendance";
      break;

    default:
      break;
  }
  return lable;
};

const GroupByKey = (list, key) => {
  var result = list.reduce(
    (hash, obj) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    }),
    {}
  );
  return result;
};

const CapitalizeEachWord = (str) => {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

const LoopWeekDay = (now) => {
  var weekdays = [];
  for (let i = 1; i <= 6; i++) {
    const day = now.clone().weekday(i);
    weekdays.push({
      name: day.format("dd"), // Format the day name (e.g., Monday)
      date: day.format("YYYY-MM-DD"), // Format the date (e.g., 2023-09-01)
    });
  }

  return weekdays;
};

const FormatRupiah = (angka, prefix) => {
  var number_string = angka
      .toString()
      .replace(/[^,\d]/g, "")
      .toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  var separator = "";
  if (ribuan) {
    separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  return prefix == undefined ? rupiah : rupiah ? rupiah : "";
};

const CleansingJSON = (data) => {
  let cleansedData = data.replace(/([{,]\s*)(\w+)\s*:/g, '$1"$2":');
  cleansedData = JSON.parse(cleansedData);
  return cleansedData;
};

export { TypeFailIDX, GroupByKey, LoopWeekDay, FormatRupiah, CapitalizeEachWord, CleansingJSON };
