import React, { useState, useRef, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  approvalSubmition,
  ASC_IS_LAISTHESISREQUIRED,
  safeBtoa,
  yyyyMmDd,
} from "../../../../Lecturer/Thesis/Activity/components/helper";
import ResendSuccessDialog from "./ResendSuccessDialog";

const ModalNewActivity = ({
  closeModal,
  optionsAdvisor,
  datas,
  getLog,
  counterLais,
  laisMandatoryData,
}) => {
  const [startDate, setStartDate] = useState(
    localStorage.getItem("startDate")
      ? new Date(localStorage.getItem("startDate"))
      : new Date()
  );
  const minDate2Days = new Date();
  minDate2Days.setDate(minDate2Days.getDate() - 2);

  const [advisorChoosen, setAdvisorChoosen] = useState(
    JSON.parse(localStorage.getItem("advisor")) ?? ""
  );
  const [description, setDescription] = useState(
    localStorage.getItem("description") ?? ""
  );
  const [loadingSubmit, setLoadingSubmit] = useState({
    loading: false,
    data: {},
    message: "",
  });
  const [showLaisMessage, setShowLaisMessage] = useState(false);
  const [showMessageFailedGetLaisState, setShowMessageFailedGetLaisState] = useState(false);
  const [resultSubmit, setResultSubmit] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [seconds, setSeconds] = useState(10);
  const [isRunning, setIsRunning] = useState(false);
  const maxLength = 1000;

  // uploaad file
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    // const fileUploaded = event.target.files[0];
    // handleFile(fileUploaded);
  };

  const handleRedirect = () => {
    const encode_data = `{{${advisorChoosen?.value?.emp_id}}}{{${
      advisorChoosen?.value?.name
    }}}{{${laisMandatoryData?.cm_course_id}}}{{${
      laisMandatoryData?.course_title
    }}}{{${laisMandatoryData.atp_id_term}}}{{${datas?.data?.studentid}}}{{${
      advisorChoosen?.value?.role === "advisor" ? 1 : 2
    }}}`;

    window.open(
      `https://survey.sgu.ac.id/lais/thesis?pg=${btoa(encode_data)}`,
      "_blank"
    );
  };

  useEffect(() => {
    let timer;
    if (isRunning && seconds > 0) {
      timer = setInterval(() => setSeconds((prev) => prev - 1), 1000);
    } else {
      setIsRunning(false);
    }

    if (seconds === 0 && !isRunning) {
      handleRedirect();
    }

    return () => {
      clearInterval(timer);
    };
  }, [seconds, isRunning]);

  const LaisComp = () => {
    return (
      <div
        className="alert alert-custom alert-outline-warning fade show mb-5"
        role="alert"
      >
        <div className="alert-icon">
          <i className="flaticon-warning"></i>
        </div>
        <div className="">
          <p className="m-0">
            You have to complete the assessment for{" "}
            {advisorChoosen?.value?.role === "advisor"
              ? "advisor"
              : "co-advisor"}{" "}
            before submitting this activity log. If you're not redirected,
            please click{" "}
            <span
              className="text-primary cursor-pointer"
              onClick={() => handleRedirect()}
            >
              here
            </span>
            . {seconds > 0 && `Redirect in ${seconds}`}
          </p>
        </div>
        <div className="alert-close">
          <button className="close" onClick={() => setShowLaisMessage(false)}>
            <span aria-hidden="true">
              <i className="ki ki-close"></i>
            </span>
          </button>
        </div>
      </div>
    );
  };

  const FailedGetLaisState = () => {
    return (
      <div
        className="alert alert-custom alert-outline-danger fade show mb-5"
        role="alert"
      >
        <div className="alert-icon">
          <i className="flaticon-danger"></i>
        </div>
        <div className="">
          <p className="m-0">
            Failed submit activity log. Please make sure your internet connection stable and try again.
          </p>
        </div>
        <div className="alert-close">
          <button className="close" onClick={() => setShowLaisMessage(false)}>
            <span aria-hidden="true">
              <i className="ki ki-close"></i>
            </span>
          </button>
        </div>
      </div>
    );
  };

  const checkLais = async () => {
    try {
      const body = {
        parameters: {
          studentid: datas?.data?.studentid,
          emp_id: advisorChoosen?.value?.emp_id,
        },
        target_data: "ASC_IS_LAISTHESISREQUIRED",
      };
      console.log("lais parameter", body);
      const cek = await ASC_IS_LAISTHESISREQUIRED(body);
      return cek;
    } catch (error) {
      console.log(error.message);
    }
  };

  // handle submit activity
  const handleSubmitActivity = async () => {
    setLoadingSubmit({ loading: true, message: "", data: [] });

    try {
      // check LAIS
      const cek_lais = await checkLais();
      console.log("cek lais", cek_lais);

      if (cek_lais.message === "") {
        // cek counter log approved + waiting
        const the_role = advisorChoosen?.value?.role;
        const the_count = counterLais[the_role];
        const limit_advisor = optionsAdvisor.length === 1 ? 7 : 4;

        console.log("the_role", the_role);
        console.log("the_role", the_role);
        console.log("limit_advisor", limit_advisor);

        if (
          (the_role === "advisor" && the_count >= limit_advisor) ||
          (the_role === "coadvisor" && the_count >= 2)
        ) {
          if (cek_lais.data === 1) {
            // show redirect message
            setSeconds(10);
            setLoadingSubmit({ loading: false, message: "", data: [] });
            setShowLaisMessage(true);
            setIsRunning(true);
          } else {
            // send log
            await sendLogActivity();
          }
        } else {
          // send log
          await sendLogActivity();
        }
      } else {
        setLoadingSubmit(cek_lais);
        setShowMessageFailedGetLaisState(true)
      }
    } catch (error) {
      setLoadingSubmit({ loading: false, message: error.message, data: [] });
      console.log(error.message);
    }
  };

  const sendLogActivity = async () => {
    try {
      const body = {
        parameters: {
          type: "act.type.thesis.activity",
          subject: safeBtoa("Thesis Activity"),
          act_date: yyyyMmDd(startDate),
          descr: safeBtoa(description),
          target_user_id: advisorChoosen.value.email,
          user_id: datas.data.studentdata.sgumail,
        },
        target_data: "CREATE_SUBMIT_ACTIVITY",
      };
      console.log(body);
      const submit = await approvalSubmition(body);

      console.log(submit);

      setLoadingSubmit(submit);
      setResultSubmit(true);
      if (submit.message === "") {
        getLog(datas.data); // call log
        setTimeout(() => {
          localStorage.removeItem("description");
          localStorage.removeItem("startDate");
          localStorage.removeItem("advisor");
          closeModal(false);
        }, 3000);
      } else {
        localStorage.setItem("description", description);
        localStorage.setItem("startDate", startDate);
        localStorage.setItem("advisor", JSON.stringify(advisorChoosen));
      }
    } catch (error) {
      setLoadingSubmit({ loading: false, message: error.message, data: [] });
      console.log(error.message);
    }
  };

  const handleTextAreaChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length > maxLength) {
      // Potong teks ke batas maksimal jika melebihi
      setDescription(inputValue.slice(0, maxLength));
    } else {
      // Perbarui teks hanya jika masih di bawah batas maksimal
      setDescription(inputValue);
    }
  };

  return (
    <>
      <div
        className="modal fade show d-block"
        data-backdrop="static"
        style={{ backgroundColor: "rgba(63, 66, 84, 0.5)" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content p-10 position-relative"
            style={{ width: 450 }}
          >
            <a
              title="close"
              className="btn btn-xs btn-icon btn-light btn-hover-danger position-absolute"
              style={{ top: 10, right: 10 }}
              onClick={() => {
                closeModal(false);
                setResultSubmit(false);
              }}
            >
              <i className="ki ki-close icon-sm text-muted"></i>
            </a>
            {resultSubmit ? (
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  {loadingSubmit.message === "" ? (
                    <ResendSuccessDialog status={loadingSubmit.message} />
                  ) : (
                    <ResendSuccessDialog
                      status={loadingSubmit.message}
                      retry={handleSubmitActivity}
                      closeModal={setResultSubmit}
                    />
                  )}
                </div>
              </div>
            ) : (
              <>
                <h4 className="modal-title" id="exampleModalLabel">
                  Add New Advisory Log
                </h4>
                <div className="mt-10">
                  <div className="w-100">
                    <div className="d-flex flex-column">
                      <div className="d-flex mb-5">
                        <div className="d-flex flex-column mr-5 w-100">
                          <p>Date</p>
                          <div className="d-flex align-items-center position-relative w-100">
                            <label className="position-relative w-100">
                              <ReactDatePicker
                                selected={startDate}
                                // minDate={minDate2Days}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="MMMM d, yyyy"
                                className="w-100 rounded border-1 border-light-primary p-2"
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: 8,
                                  right: 10,
                                }}
                              >
                                <i className="icon-md far fa-calendar-alt"></i>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-column gap-2 w-100">
                          <p>
                            Advisor<span className="text-danger">*</span>
                          </p>
                          <div>
                            <Select
                              options={optionsAdvisor}
                              value={advisorChoosen}
                              onChange={(e) => setAdvisorChoosen(e)}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: "#f3f4f6",
                                  outline: "none",
                                  boxShadow: "none",
                                  "&:hover": {
                                    borderColor: "#f3f4f6",
                                  },
                                }),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column w-100">
                        <p>
                          Description<span className="text-danger">*</span>
                        </p>
                        <textarea
                          rows={4}
                          name="description"
                          placeholder="Describe your work"
                          className="border rounded p-2"
                          style={{
                            outline: "none",
                            borderColor: "#E4E6EF",
                            outlineColor: "#7E8299",
                          }}
                          value={description}
                          onChange={handleTextAreaChange}
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            textAlign: "right",
                          }}
                        >
                          <span
                            className={`${
                              description.length > 950 &&
                              "text-danger font-weight-bolder font-size-lg"
                            }`}
                          >
                            {description.length}
                          </span>
                          /{maxLength}
                        </p>
                      </div>
                      {/* attachment */}
                      {/* <div
                      className="d-flex w-50 mt-5 cursor-pointer"
                      onClick={handleClick}
                    >
                      <i className="icon-lg la la-paperclip mr-1"></i>
                      <p className="text-muted">Add Attachment</p>
                      <input
                        type="file"
                        onChange={handleChange}
                        ref={hiddenFileInput}
                        disabled
                        className="position-absolute"
                        style={{ display: "none" }}
                      />
                    </div> */}
                    </div>
                  </div>
                </div>
                <label className="checkbox d-flex align-items-start mb-5">
                  <input
                    type="checkbox"
                    checked={checkbox}
                    onChange={() => setCheckbox(!checkbox)}
                  />
                  <span></span>
                  <p className="p-0 m-0 pl-1">
                    I acknowledge that this record cannot be edited after
                    submission.
                  </p>
                </label>
                {showLaisMessage && <LaisComp />}
                {showMessageFailedGetLaisState && <FailedGetLaisState />}
                <button
                  type="button"
                  className={`btn ${
                    loadingSubmit.loading ? "btn-light-primary" : "btn-primary"
                  }`}
                  disabled={
                    description === "" ||
                    advisorChoosen === "" ||
                    !checkbox ||
                    isRunning
                  }
                  onClick={() =>
                    !loadingSubmit.loading && handleSubmitActivity()
                  }
                >
                  {loadingSubmit.loading ? "Loading..." : "Submit"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalNewActivity;
