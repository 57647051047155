import React from "react";
import "./App.css";
import packageJson from "../package.json";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  TabletView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import jwt_decode from "jwt-decode";
import UnderMaintenance from "./components/Templates/UnderMaintenance";
import LoginForm from "./components/Auth/View/Form";
import AxiosLib from "./components/Library/AxiosLib";
import PageNotFound from "./components/Templates/PageNotFound";
import LayoutMobile from "./components/MobileAppsView/LayoutMobile";
import LayoutNew from "./components/Templates/LayoutNew";

function App() {
  const baseUri = window.location.href;
  if (!baseUri.includes("localhost")) {
    console.log = console.warn = console.error = () => {};
    console.warn = () => {};
  }

  const IsAuth = localStorage.getItem("AuthToken");
  const dispatch = useDispatch();
  const SignOutPage = () => {
    dispatch({ type: "SIGN_OUT" });
  };
  const [IsError, setIsError] = useState({ title: "-", value: false });
  const [maintenance, setMaintenance] = useState([]);

  const fetchingMaintenance = () => {
    AxiosLib(
      { PortalID: packageJson.system_param.PortalID, CurrentDate: new Date() },
      "mc/api/maintenance-portal"
    )
      .then((response) => {
        if (response.data) {
          setMaintenance(response.data);
        } else if (response.error) {
          setIsError({ title: response.error, value: true });
        }
      })
      .catch((error) => {
        console.log("errror", error);
        //alert("Error Connection Refuse");
      });
  };

  useEffect(() => {
    fetchingMaintenance();
  }, []);

  const decode_token = IsAuth ? jwt_decode(IsAuth) : [];
  console.log(decode_token);
  const current_role = decode_token.identity?.roleid;
  console.log(current_role);

  return (
    <Switch>
      {!IsAuth ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        IsError.value ? (
          <UnderMaintenance
            data={{
              Title: "Blocked by your network",
              Description: "Error Connection Refuse.<br/> " + IsError.title,
            }}
          />
        ) : Object.keys(maintenance).length > 0 ? (
          <UnderMaintenance data={maintenance[0]} />
        ) : (
          <Route path="/sign-in" component={LoginForm} />
        )
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/sign-in" to="/" />
      )}

      <Route path="/sign-out" component={SignOutPage} />

      {!IsAuth ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/sign-in" />
      ) : IsError.value ? (
        <UnderMaintenance
          data={{
            Title: "Blocking by your network",
            Description: "Error Connection Refuse.<br/> " + IsError.title,
          }}
        />
      ) : Object.keys(maintenance).length > 0 ? (
        <UnderMaintenance data={maintenance[0]} />
      ) : (
        <>
          {isTablet && (
            <TabletView>
              <LayoutNew />
            </TabletView>
          )}
          {isMobile && !isTablet && (
            <MobileView>
              {current_role !== 1 ? <LayoutNew /> : <NotAllowed />}
            </MobileView>
          )}
          {(isBrowser || (!isTablet && !isMobile)) && (
            <BrowserView>
              <LayoutNew />
            </BrowserView>
          )}
        </>
      )}
      <Route component={PageNotFound} />
    </Switch>
  );
}

const NotAllowed = () => {
  return (
    <div className="w-100 w-lg-500px ">
      <div className="card card-custom gutter-b shadow">
        <div className="card-body">
          <h4 className="text-center">My SGU portal cannot be accessed via a mobile browser. Please use your SGU Mobile app.</h4>
          <div className="text-center my-5">
            <a href="https://play.google.com/store/apps/details?id=id.ac.sgu.mobile&pcampaignid=web_share" className="mr-2">
              <img src={require("./media/logos/app-play-store.png")} alt="" className="w-100px" />
            </a>
            <a href="https://apps.apple.com/id/app/sgu-mobile-app/id6448629664?itsct=apps_box_badge&amp;itscg=30200">
              <img src={require("./media/logos/app-store.png")} alt="" className="w-100px" />
            </a>

            <div className="mt-5">
              <a href="/sign-out" className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">
                Sign Out
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
