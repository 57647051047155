import React, { useState, useEffect } from 'react'
import jwt_decode from "jwt-decode";
import packageJson from "../../../../package.json";
import { CheckIsLais, CheckSurvey, FetchMenu } from './APIs';
import { CardLais, CardSessionTimeout, CardUnregis } from './CardInfo';
import PageNotFound from '../PageNotFound';
import LoadingBtn from '../../Page/Widgets/LoadingBtn';
import { AlertNotif } from '../../Library/AlertNotif';
import { RouteLecturer, RouteStudent } from '../../Routes';

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export default function PageContent() {
    let encstudentid = "";
    if (AuthDecode) {
        encstudentid = Buffer.from(
            AuthDecode.currentData.entityid.toString()
        ).toString("base64");
    } 
    var currDatetime = new Date();
    currDatetime = currDatetime.getTime();
    const [menu, setMenu] = useState({ loading: false, message: "", data: [] });

    var urlLais = packageJson.system_param.LAIS + "lais/index?pg=" + encstudentid;
    var urlSurvey = packageJson.system_param.LAIS + "internship/";
    
    const [lais, setLais] = useState({ loading: false, message: "", data: 0 });
    const [internship, setInternship] = useState({ loading: false, message: "", data: [] });

    useEffect(() => {
        FetchMenu(setMenu);
        CheckIsLais(setLais, urlLais);
        CheckSurvey(setInternship, urlSurvey, encstudentid)
    }, [])

    if (AuthDecode) {
        if (AuthDecode.currentData.entityid === 0) {
            return <CardUnregis />;
        } else if (currDatetime >= AuthDecode.ExpiredSession) {
            return <CardSessionTimeout />;
        } else {
            if (lais.loading || internship.loading || menu.loading) {
                return (
                    <div className="text-center">
                        <LoadingBtn />
                    </div>
                )
            } else if (lais.data > 0) {
                return <CardLais url={urlSurvey} title="LAIS" />
            } else if (internship.data > 0) {
                return <CardLais url={urlSurvey} title="Internship" />
            } else if (menu.message) {
                return <AlertNotif message={menu.message} color="danger" />
            } else if (Object.values(menu.data).length > 0) {
                return AuthDecode.currentData.roleid == 1 ? <RouteStudent data={menu.data} /> : <RouteLecturer data={menu.data} />
            } else {
                return ""
            }
        }
    } else {
        return <PageNotFound />
    }
}