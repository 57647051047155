import React, { useRef, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import "./FormRevisi.css";
import { Editor } from "@tinymce/tinymce-react";

export default function FormRevisi(props) {
  const currSection = props.data ? props.data : null;
  const editorRef = useRef(null);
  const currExaminer = props.data.ths_main_ex ? props.data.ths_main_ex : null;

  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const postRev = props.postRev;
  const setPostRev = props.setPostRev;
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setCharCount(postRev.revision ? postRev.revision.length : 0);
  }, []);

  return (
    <div id="form-thesis-score" className="p-10">
      <h3>
        <span className="font-weight-bolder text-dark">Revision Notes</span>
      </h3>
      <div className="row">
        <div className={"col-md-12 col-lg-12"}>
          <div className="form-group row justify-content-center">
            <div className={"form-group col-sm-12 "}>
              <Editor
                apiKey="sxd4e2dzs0r4qtfz7zzrh63khnixp6vk68il4znsxxuo1tym"
                className="required"
                value={postRev.revision}
                onInit={(evt, editor) => (editorRef.current = editor)}
                onEditorChange={(newValue, editor) => {
                  const text = editor.getContent({ format: 'text' });
                  if (text.length <= 5000) {
                    setPostRev({ ...postRev, revision: newValue });
                    setCharCount(text.length);
                  } else {
                    editor.setContent(postRev.revision);
                  }
                }}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  automatic_uploads: true,
                  default_link_target: "_blank",
                  link_assume_external_targets: true,
                  plugins: "paste",
                  paste_as_text: true,
                  paste_remove_styles: true,
                  paste_remove_spans: true,
                  paste_strip_class_attributes: "all",
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor  link | alignleft aligncenter |" +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    setup: (editor) => {
                      editor.on('KeyUp', (e) => {
                        const text = editor.getContent({ format: 'text' });
                        setCharCount(text.length);
                      });
                    },
                }}
              />
              <div className="mt-2">
                <span className={charCount > 5000 ? 'text-danger' : ''}>
                  Character count: {charCount}/5000
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
