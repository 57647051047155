import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
import LoadingBtn from "../../../Widgets/LoadingBtn";
import { AlertNotif } from "../../../../Library/AlertNotif";
import { openModal } from "../../../../Library/ModalPopUp";

export default function SemesterScore({ stdStatus }) {
  const objSemes = {
    atpid: stdStatus[0].atp_term ? stdStatus[0].atp_term.id : 0,
    data: stdStatus[0],
  };
  const [selectedSemes, setSemester] = useState(objSemes);

  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

  const [selectedType, setType] = useState("fin");
  const studentid = AuthDecode.currentData.entityid;

  const handlerChooseSemester = (value) => {
    var value = parseInt(value);
    var findData = stdStatus.find((item) => item.atp_term.id === value);
    setSemester({ atpid: value, data: findData });
    var paramobj = {
      studentid: studentid,
      atpid: value,
    };
    GET_SCR_STUDENT_BY_ATPID(paramobj);
    paramobj.semester = findData.semester;
    GET_STUDENT_SEMESTER_STATUS(paramobj);
  };

  const [scrStd, setScrStd] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const [totalAja, setTotalAja] = useState({
    tcredit: 0,
    tlesson: 0,
    tmerit: 0,
    tmeritfin: 0,
  });

  const GET_SCR_STUDENT_BY_ATPID = (param) => {
    setScrStd({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: param,
      target_data: "GET_SCR_STUDENT_BY_ATPID",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setScrStd({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        const code_error = response.response.message.code;
        let message_error = response.response.message.user;
        if(code_error && code_error === "02016"){
          message_error += "Code ("+code_error+")";
        }
        setScrStd({
          loading: false,
          data: [],
          message: message_error,
        });
      } else {
        var results = response.response.data;
        if (results) {
          var tCredit = results.reduce(
            (a, v) =>
              (a =
                a +
                (v.course.course_type !== "asc.course.type.optional"
                  ? v.credit
                  : 0)),
            0
          );
          var tLesson = results.reduce((a, v) => (a = a + v.course_lesson), 0);
          var tMerit = results.reduce((a, v) => (a = a + v.merit), 0);
          var tMeritFin = results.reduce((a, v) => (a = a + v.merit_fin), 0);
          setTotalAja({
            tcredit: tCredit,
            tlesson: tLesson,
            tmerit: tMerit,
            tmeritfin: tMeritFin,
          });
          setScrStd({ loading: false, data: results, message: "" });
        } else {
          setScrStd({
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  const [stdSemes, setStsSemes] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const GET_STUDENT_SEMESTER_STATUS = (param) => {
    setStsSemes({ loading: true, data: [], message: "" });
    var keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    var body = {
      parameters: param,
      target_data: "GET_STUDENT_SEMESTER_STATUS",
    };
    const postData = {
      keys: keys,
      body: body,
    };

    AxiosLibWithHeader2(postData, "app/api/req-module").then((response) => {
      if (response.error) {
        setStsSemes({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        setStsSemes({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        var results = response.response.data;
        if (results) {
          setStsSemes({ loading: false, data: results, message: "" });
        } else {
          setStsSemes({
            loading: false,
            data: [],
            message: "No record found",
          });
        }
      }
    });
  };

  useEffect(() => {
    var paramobj = {
      studentid: studentid,
      atpid: selectedSemes.atpid,
    };
    GET_SCR_STUDENT_BY_ATPID(paramobj);
    paramobj.semester = selectedSemes.data.semester;
    GET_STUDENT_SEMESTER_STATUS(paramobj);
  }, []);

  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark">Semester Score</span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Please choose the semester for view score
          </span>
        </h3>
        <div className="card-toolbar">
          <div className="form-group mb-0">
            <select
              name="atp_id"
              className="form-control"
              defaultValue={selectedSemes.atpid}
              onChange={(e) => handlerChooseSemester(e.target.value)}
            >
              <option value="">Choose Semester</option>
              {stdStatus.map((v, index) => (
                <option value={v.atp_term ? v.atp_term.id : ""} key={index}>
                  Semester {v.semester}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="card-body">
        {scrStd.loading || stdSemes.loading ? (
          <LoadingBtn />
        ) : scrStd.message || stdSemes.message ? (
          (scrStd.message.includes("02016") ? (
            <AlertBlockingScore />
          ) : (
            <AlertNotif
              message={scrStd.message || stdSemes.message}
              color={"danger"}
            />
          ))
        ) : Object.values(scrStd.data).length > 0 ? (
          <div className="table-responsive">
            <table className="table table-vertical-center" >
              <thead>
                <tr className="bg-light">
                  <th>No</th>
                  <th>Subject</th>
                  <th>Credit</th>
                  <th>Points</th>
                  <th>Grade</th>
                  <th>Absence</th>
                  <th>Final Result</th>
                  <th>Remark</th>
                  <th>Detail</th>
                </tr>
              </thead>
              <tbody>
                {scrStd.data.map((v, index) => {
                  const score_type = v.course
                  ? v.course.score_type
                    ? v.course.score_type
                    : null
                  : null;
                  if(score_type === 1)
                    return <RowNumeric v={v} index={index} key={index} />;
                  else if(score_type === 2)
                    return <RowLetter v={v} index={index} key={index} />
                })}
              </tbody>
              {Object.values(stdSemes.data).length > 0 ? (
                <tfoot className="bg-light">
                  <tr>
                    <td colSpan={2} className="font-weight-bolder">
                      Average
                    </td>
                    <td></td>
                    <td className="font-weight-bolder">
                      {stdSemes.data[0].avg_point}
                    </td>
                    <td className="font-weight-bolder">
                      {stdSemes.data[0].avg_grade}
                    </td>
                    <td colSpan={4}></td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="font-weight-bolder">
                      Total
                    </td>
                    <td colSpan={7} className="font-weight-bolder">{totalAja.tcredit}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bolder" colSpan={2}>
                      GPA
                    </td>
                    <td colSpan={10} className="text-left font-weight-bolder">
                      {stdSemes.data[0].gpa}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bolder" colSpan={2}>
                      CGPA
                    </td>
                    <td colSpan={10} className="text-left font-weight-bolder">
                      {stdSemes.data[0].cgpa}
                    </td>
                  </tr>
                </tfoot>
              ) : (
                ""
              )}
            </table>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

const RowNumeric = ({ v, index }) => {
  return (
    <tr className={v.grade_fin == "F" ? "bg-danger" : ""}>
      <td>{index + 1}</td>
      <td>{v.course ? v.course.coursetitle : "-"}</td>
      <td>{v.credit}</td>
      <td>{v.score ? v.score : 0}</td>
      <td>{v.grade_fin ? v.grade_fin : "-"}</td>
      <td>{v.course ? ((v.course.is_atd_calc) ? (v.course.is_atd_calc === 1 ? ((v.atd) ? v.atd+"%" : "-") : "-") : "-") :"-"}</td>
      <td>{v.course_status || ""}</td>
      <td>{v.remark}</td>
      <td>
        {Object.values(v.section_com).length > 0 ? (
          !v.course.is_thesis && !v.course.is_internship ? 
          (<button
            className="btn btn-sm btn-light btn-icon"
            type="button"
            onClick={() =>
              HandlerDetailScore(
                v.section_com,
                v.course ? v.course.coursetitle : "-"
              )
            }
          >
            <i className="fas fa-chevron-circle-right text-primary"></i>
          </button>)
          : ""
        ) : (
          ""
        )}
      </td>
    </tr>
  );
};

const RowLetter = ({ v, index }) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{v.course ? v.course.coursetitle : "-"}</td>
      <td>{v.credit}</td>
      <td>-</td>
      <td>-</td>
      <td>{v.course ? ((v.course.is_atd_calc) ? (v.course.is_atd_calc === 1 ? v.atd+"%" : "-") : "-") :"-"}</td>
      <td>{v.course_status || ""}</td>
      <td>{v.remark}</td>
      <td></td>
    </tr>
  );
};

const HandlerDetailScore = (data, coursetitle) => {
  const sortId = data.sort((a, b) =>
    a.wg_com_id > b.wg_com_id ? 1 : -1
  );
  const ascScrComWeight = sortId.sort((a, b) =>
    a.weight > b.weight ? 1 : -1
  );
  const DetailScore = () => {
    return (
      <div className="table-responsive">
        <table className="table table-head-bg table-borderless table-vertical-center">
          <thead>
            <tr className="text-left text-dark text-uppercase">
              <th width="8%">No</th>
              <th>Grade Component</th>
              <th>Weight</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(ascScrComWeight).length > 0 ? (
              ascScrComWeight.map((v, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {v.name ? decodeURIComponent(v.name) || "" : ""} 
                  </td>
                  <td>{v.weight * 100}%</td>
                  <td>{v.score}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No record found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  openModal({ header: coursetitle, message: <DetailScore />, size: "lg" });
};

const AlertBlockingScore = () =>{
  return (
    <div className="alert alert-custom alert-outline-2x alert-light-primary fade show mb-5" role="alert">
      <div className="alert-icon">
        <i className="flaticon-warning"></i>
      </div>
      <div className="alert-text font-size-lg">
        Your semester score will be available after the semester grade release date marked on your <a href="https://sgu.ac.id/academic-services/academic-calendar/" className="alert-link" target="_blank">Academic Calendar</a>
      </div>
    </div>
  )
}