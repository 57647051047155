import React from 'react'

const Card = ({ title, icon, name }) => {
    return (
      <div className="bg-white p-5 rounded h-100">
        <div className="card-body p-0">
          <div className="card-p d-flex flex-column">
            {icon === "a" ? (
              <i className="icon-lg text-primary fas fa-user-circle"></i>
            ) : (
              <i className="icon-lg fas fa-book text-primary"></i>
            )}
            <p className="p-0 m-0 py-2" style={{fontSize: 14, fontWeight: 400}}>{title}</p>
            <p style={{ fontSize: 16, fontWeight: 500 }}>{name}</p>
          </div>
        </div>
      </div>
    );
  };

export default Card