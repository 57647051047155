import CryptoJS from "crypto-js";

import jwt_decode from "jwt-decode";
import AxiosLibWithHeader2 from "../../../../../Library/AxiosLibWithHeader2";

var AuthToken = localStorage.getItem("AuthToken");
const AuthDecode = AuthToken ? jwt_decode(AuthToken) : [];

export function setHeader(secretkey, payload) {
  let AppID = "Test-AccounT-StReSs-fULL-3ef7eb4d666";
  let d = new Date();
  let tstamp = d.getTime() / 1000;

  let trimmed_payload = payload.replace(/\s+/g, "");
  let base64 = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(trimmed_payload)
  );
  let signature_raw = tstamp + "|<->|" + base64;
  let signature_bytes = CryptoJS.HmacSHA256(signature_raw, secretkey);
  let signature = CryptoJS.enc.Base64.stringify(signature_bytes);

  let headers = {
    "App-Id": AppID,
    "App-Env": "sgu-my-sgu",
    "App-Signature": signature,
    "App-Timestamp": tstamp,
    "Content-Type": "application/json",
  };

  return headers;
}

export function formatDate(dateStr) {
  const date = new Date(dateStr);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

// yyyy-mm-dd
export function yyyyMmDd(dateStr) {
  const dateObj = new Date(dateStr);

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(dateObj.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export async function dataFetcher(body) {
  try {
    const keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    const postData = {
      keys: keys,
      body: body,
    };
    const fetch = await AxiosLibWithHeader2(postData, "app/api/req-module");
    // console.log("helper -> ", fetch);
    if (fetch.error) {
      return { loading: false, data: [], message: fetch.error }; // error koneksi
    } else if ("message" in fetch.response) {
      const msg = fetch.response.message.user;
      return { loading: false, data: [], message: msg }; // error jaguar
    } else {
      const results = fetch.response.data;
      console.log("results -> ", results);
      if (Object.keys(results).length > 0) {
        return { loading: false, data: results, message: "" };
      } else {
        return { loading: false, data: [], message: "No record found" }; // no data
      }
    }
  } catch (error) {
    console.log("sini")
    return { loading: false, data: [], message: error.message };
  }
}

export async function approvalSubmition(body) {
  try {
    const keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    const postData = {
      keys: keys,
      body: body,
    };
    // console.log("helper");
    const fetch = await AxiosLibWithHeader2(postData, "app/api/req-module");
    // console.log(response);
    if (fetch.error) {
      return { loading: false, data: [], message: fetch.error }; // error koneksi
    } else if ("message" in fetch.response) {
      const msg = fetch.response.message.user;
      return { loading: false, data: [], message: msg }; // error jaguar
    } else {
      const results = fetch.response.data;
      if (Object.keys(results).length > 0) {
        return { loading: false, data: results, message: "" };
      } else {
        return { loading: false, data: [], message: "No record found" }; // no data
      }
    }
  } catch (error) {
    console.log("helper error");
    return { loading: false, result: "", message: error };
  }
}

export async function ASC_IS_LAISTHESISREQUIRED(body) {
  try {
    const keys = {
      AppId: AuthDecode.AppID,
      AppEnv: AuthDecode.AppEnv,
      Secretkey: AuthDecode.secretkey,
    };
    const postData = {
      keys: keys,
      body: body,
    };
    // console.log("helper");
    const fetch = await AxiosLibWithHeader2(postData, "app/api/req-module");
    // console.log(fetch);
    if (fetch.error) {
      return { loading: false, data: [], message: fetch.error }; // error koneksi
    } else if ("message" in fetch.response) {
      const msg = fetch.response.message.user;
      return { loading: false, data: [], message: msg }; // error jaguar
    } else {
      return { loading: false, data: fetch.response.data, message: "" };
    }
  } catch (error) {
    console.log("helper error");
    return { loading: false, result: "", message: error };
  }
}

export function safeDecode(text) {
  try {
    return atob(text);
  } catch (e) {
    return text; // Jika gagal, kembalikan teks aslinya
  }
}

export function safeBtoa(str) {
  console.log(str);
  const utf8Bytes = new TextEncoder().encode(str);
  return btoa(String.fromCharCode.apply(null, utf8Bytes));
}

export function safeAtob(str) {
  return decodeURIComponent(escape(atob(str)));
}
